/**
 * Created by Max Gornostayev on 05/01/22
 *
 * path: /auth/passwort?token={token}
 * id: authSignupStepFour
 *
 * this is a page that shows fourth-step registration form, we confirm email on this page and set the password.
 * To confirm password we need to get token from param and send to the server
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Alert from '../../components/popups/Alert';
import InputText from '../../components/forms/InputText';
import Header from '../../components/theme/Header';
import Loading from '../../components/elements/Loading';
import Validation from '../../lib/Validation';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';

const SignupStepFourPage = observer(({ UserStore, ContractsStore }) => {
    //get contractTemp
    const { customer } = UserStore;

    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(customer.password);
    const [emailPassToken, setEmailPassToken] = useState('');
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    //ref variables
    const refAlert = useRef();
    const refPassword = useRef();
    const refRePassword = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get prop 'tokenProp' from another screen
    const { state } = useLocation();

    //get search query object
    const [searchParams] = useSearchParams();

    const email = searchParams.get('email') || '';
    const companyValue = searchParams.get(process.env.REACT_APP_CUSTOMER_COMPANY_KEY) || '';
    const isCompany = companyValue === process.env.REACT_APP_CUSTOMER_COMPANY_VALUE;

    //confirm email if we have token param inside query of the url
    useEffect(async () => {
        const emailToken = searchParams.get('token');
        if (emailToken) {
            const res = await UserStore.emailConfirm(emailToken);
            if (res.status) {
                setEmailPassToken(res.data.passwordToken);
                setIsEmailConfirmed(true);
                refAlert.current.show(trans.t('signupStepFourPage', 'successMsg'));
            } else {
                refAlert.current.error(res.msg);
            }
        }
    }, []);

    //button handler
    const next = async () => {
        if (isCompany && !isEmailConfirmed) {
            refAlert.current.error(trans.t('signupStepFourPage', 'emailNotConfirmed'));
            return false;
        }
        let formValidated = true;

        let validObj = refPassword.current.validate();
        if (!validObj.isValid) {
            refPassword.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refRePassword.current.validate();
        if (!validObj.isValid) {
            refRePassword.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        if (!formValidated) {
            return false;
        }

        setIsLoading(true);

        //get token from query string, if it's passing from previous step
        const tokenProp = state && state.hasOwnProperty('token') ? state.token : '';
        const token = emailPassToken || tokenProp;

        const res = await UserStore.updatePassword(password, token);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        UserStore.clearStore();
        ContractsStore.clearStore();
        UserStore.setIsCompany(isCompany);
        navigate(pageUrl.authSigninPage, { state: { email, phone: customer.phoneTitle } });
    };

    //main rendering
    return (
        <div className="app auth signup-step-4-page">
            <Header>
                <h1>{trans.t('signupStepFourPage', 'h1')}</h1>
            </Header>
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        {isCompany && (
                            <InputText title={trans.t('signupStepFourPage', 'email')} type="text" readOnly defaultValue={email} className="readonly-field input-text margin-top-16" />
                        )}
                        {!isCompany && (
                            <InputText
                                title={trans.t('signupStepFourPage', 'phoneNumber')}
                                type="text"
                                readOnly
                                defaultValue={customer.phoneTitle}
                                className="readonly-field input-text margin-top-16"
                                validateFunc={(val) => Validation.password(val)}
                            />
                        )}
                        <InputText
                            ref={refPassword}
                            role="passwordTestId"
                            title={trans.t('signupStepFourPage', 'password')}
                            type="password"
                            className="margin-top-16"
                            onChange={(val) => setPassword(val)}
                            validateFunc={(val) => Validation.password(val)}
                        />
                        <InputText
                            ref={refRePassword}
                            role="rePasswordTestId"
                            title={trans.t('signupStepFourPage', 'rePassword')}
                            type="password"
                            className="margin-top-16"
                            validateFunc={(val) => Validation.passwordConfirm(val, password)}
                        />
                        <Button role="btnNext" text={trans.t('signupStepFourPage', 'btnNext')} onClick={next} isRightIcon isFullWidth className="margin-top-48" />
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignupStepFourPage;
