const terms = [
    //terms service
    { type: 'secTitle', text: "Gegenstand der Bedingungen"},
    { type: 'textTitl', text: "Die nachfolgenden Hinweise gelten für den Zugriff auf und die Nutzung der auf der Internetpräsenz http://www.owlvv.de angebotenen Informationen und Materialien. Jeder Besucher dieser Präsenz wird gebeten, diese Hinweise aufmerksam zur Kenntnis zu nehmen. Mit Verwendung der Internetpräsenz werden die Nutzungsbedingungen zu dem Angebot als verbindlich anerkannt."},
    { type: 'textTitl', text: "Nutzungsrecht an den Inhalten"},
    { type: 'textTitl', text: "Die Web-Seiten, ihre Programmierung, Inhalte, Gestaltung und Struktur unterliegen urheber-, marken- und wettbewerbsrechtlichen Schutzrechten. Urheberrechtshinweise und Markenbezeichnungen dürfen weder verändert noch beseitigt werden. Eine Vervielfältigung, Verbreitung, Bereithaltung zum Abruf oder Online-Zugänglichmachung (Übernahme in andere Webseite) unserer Webseiten, des Layouts der Webseiten, ihrer Inhalte (Texte, Bilder, Programme) ganz oder teilweise, in veränderter oder unveränderter Form ist nur nach vorheriger schriftlicher Zustimmung zulässig. Lediglich die nicht-kommerzielle private Nutzung ist in den Grenzen des Urheberrechtsgesetzes zulässig."},
    { type: 'textTitl', text: "Haftung für Inhalte/Informationen"},
    { type: 'textTitl', text: "Die OWL Versicherungskontor GmbH (nachstehend OWL-VK genannt) sowie Dritte, von denen die OWL-VK Informationen auf der Webseite bereitstellt, übernehmen trotz sorgfältiger Beschaffung und Bereitstellung keine Haftung für die Richtigkeit, Vollständigkeit, Verzögerung oder Genauigkeit sowie Verfügbarkeit der im Rahmen dieses Informationsangebotes zum Abruf bereitgehaltenen und angezeigten Börsen- und Wirtschaftsinformationen, Preise, allgemeinen Marktdaten und sonstigen zugänglichen Inhalte. Die OWL-VK hat nicht alle Informationen, auf die sich die Dokumente stützen, selbst verifiziert. Die OWL-VK übernimmt keine Haftung für Verluste, die durch die Verteilung/Verwendung dieser Dokumente/Informationen verursacht oder mit der Verteilung/Nutzung dieser Dokumente/Informationen im Zusammenhang stehen."},
    { type: 'textTitl', text: "Beratung/Anlageempfehlung"},
    { type: 'textTitl', text: "Die hier bereitgestellten Dokumente/Informationen sind ausschließlich zur Information bestimmt und können ein individuelles Beratungsgespräch nicht ersetzen. Ein Abschluss sollte auf Grundlage eines Beratungsgespräches erfolgen und auf keinen Fall auf der Grundlage dieser Informationen. Die Informationen können durch aktuelle Entwicklungen überholt sein, ohne dass die bereitgestellten Informationen geändert wurden. Angebot: Die Einstellung dieser Informationen ins Internet stellt kein Angebot zum Erwerb einer Versicherungspolice dar."},
    { type: 'textTitl', text: "Hinweise zur E-Mail Kommunikation:"},
    { type: 'textTitl', text: "Die Kommunikation per E-Mail hat sich als schnelle, bequeme und kostengünstige Kommunikationsart in den letzten Jahren als Massenmedium eingebürgert. Systemtechnisch bedingt hat diese Kommunikationsform aber auch Nachteile. So besteht grundsätzlich die Möglichkeit, dass über das offene Computernetzwerk Internet versandte E-Mail-Nachrichten von unbefugten Personen zur Kenntnis genommen (fehlende Vertraulichkeit) und gar verändert werden (fehlende Integrität) können. Auch lässt sich der Absender einer E-Mail verändern (fehlende Authentizität). Beachten Sie bitte bei E-Mail-Sendungen an die OWL-VK, dass dieses Kommunikationsmedium nicht die Vertraulichkeit und Sicherheit der Briefkommunikation aufweist, sondern sich in dieser Hinsicht mit einer Postkarte vergleichen lässt. Prüfen Sie deshalb kritisch, welche Informationen Sie uns per E-Mail übermitteln. Da sich derzeit noch kein Signatur- und Verschlüsselungsverfahren allgemein als Standard durchgesetzt hat, welches den Anforderungen eines Kreditinstitutes an die Sicherheit und Vertraulichkeit erfüllt, können wir Ihnen noch kein derartiges Verfahren zur Absicherung der E-Mail Kommunikation anbieten.Bitte berücksichtigen Sie ebenfalls, dass wir über unsere Kontaktformulare bzw. per E-Mail keine Aufträge entgegennehmen können."},
    { type: 'textTitl', text: "Datenschutz"},
    { type: 'textTitl', text: "Sofern Sie uns persönliche Daten mitteilen, werden wir diese mit üblicher Sorgfalt und entsprechend der datenschutzrechtlichen Bestimmungen behandeln (siehe Datenschutzbedingungen)."},
    { type: 'textTitl', text: "Links"},
    { type: 'textTitl', text: "Links auf Unterseiten und Unterverzeichnisse (Deep- Links, Img-Links) sind nur nach vorheriger schriftlicher Zustimmung der OWL-VK zulässig. Des Weiteren haftet die OWL-VV nicht für Inhalte von Drittanbietern, auf die aus ihrer Präsenz direkt oder indirekt durch „Links“ verwiesen wird. Die Inhalte dieser durch Link verbundenen Seiten hat die OWL-VK nicht autorisiert bzw. werden nicht fortlaufend durch sie überprüft."},
    { type: 'textTitl', text: "Frames"},
    { type: 'textTitl', text: "Die Einbindung von eigenen Webseiten in eine Rahmenstruktur (Frames) ist nur nach vorheriger schriftlicher Zustimmung der OWL-VK zulässig."},
    { type: 'textTitl', text: "Markenzeichen:"},
    { type: 'textTitl', text: "Alle hier erwähnten Produktbezeichnungen dienen lediglich Identifikationszwecken und können Marken bzw. eingetragene Marken jeweiliger Unternehmen sein."},
    { type: 'textTitl', text: "Rechtswahl und Gerichtsstand: Das Nutzungsverhältnis der OWL-VK-eigenen Webseiten unterliegt deutschem Recht."},
];

export default terms;
