/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows video from youtube
 */

import YouTube from 'react-youtube';

export default function YoutubeVideo({ videoId, width, height, autoplay }) {
    const opts = { playerVars: { autoplay: autoplay !== 0 ? 1 : 0 } };

    if (width) {
        opts.width = width;
    }

    if (height) {
        opts.height = height;
    }

    return (
        <div className="youtube-element">
            <YouTube videoId={videoId} opts={opts} />
        </div>
    );
}
