/**
 * Created by Max Gornostayev on 04/28/22
 *
 * This is a router for routing logic on onboarding proccess
 */

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import pageUrl from '../const/pages';

const RouteOnboarding = ({ UserStore, ContractsStore }) => {
    //get search query object
    // const [searchParams] = useSearchParams();
    // const affiliateID = searchParams.get('affiliateID') || '';
    // const referalID = searchParams.get('referalID') || '';
    //
    // if (affiliateID || referalID) {
    //     UserStore.setAffiliateIds({ affiliateID, referalID });
    // }

    //check if terms is accepted
    if (UserStore.isNotTermsAccepted) {
        return <Navigate to={pageUrl.authStartRegister} />;
    }
    //check if contracts is added to store before user adding to the server, that we can add new user
    const location = useLocation();
    const { checkedIds } = ContractsStore;

    //first check userData page
    if (location.pathname === pageUrl.onboardingUserData) {
        if (!ContractsStore.getNumberOfContracts && !checkedIds.length) {
            return <Navigate to={pageUrl.onboardingContractAdd} />;
        }
    }
    //check if contracts and users are added to the server that we cna sign broker
    if (location.pathname === pageUrl.onboardingSignBroker || location.pathname === pageUrl.onboardingSignPrivacy || location.pathname === pageUrl.onboardingBrokerSuccess) {
        if (!checkedIds.length) {
            return <Navigate to={pageUrl.onboardingContractAdd} />;
        }
        const { customer } = UserStore;
        if (customer.isTemp) {
            return <Navigate to={pageUrl.onboardingUserData} />;
        }
    }

    return <Outlet />;
};

export default RouteOnboarding;
