/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is not found page
 */

import React from 'react';

const NotFound = () => {
    return (
        <div className="missing-page">
            <div className="missing-page-header">
                <h1 className="heading">404 – Not Found</h1>
            </div>
        </div>
    );
};

export default NotFound;
