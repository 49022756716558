/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show formated text for terms files
 */

export default function TextsFromFile({ texts }) {
    //render function that render item
    // obj: {type: string, text: string}
    const renderItem = (obj, index) => {
        if (!obj.text) {
            return '';
        }
        switch (obj.type) {
            case 'secTitle':
                return (
                    <div key={index} className="title">
                        {obj.text}
                    </div>
                );
            case 'catTitle':
                return (
                    <div key={index} className="category">
                        {obj.text}
                    </div>
                );
            case 'subTitle':
                return (
                    <div key={index} className="subcategory">
                        {obj.text}
                    </div>
                );
            case 'subsubTi':
                return (
                    <div key={index} className="subsubcategory">
                        {obj.text}
                    </div>
                );
            case 'textTitl':
            case 'textSubT':
            default:
                return (
                    <div key={index} className="text">
                        {obj.text}
                    </div>
                );
        }
    };

    return <div className="container-text-file-element">{texts.map((textObj, index) => renderItem(textObj, index))}</div>;
}
