/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractListFinish
 * path: /onboarding/makler/info
 *
 * this is a success page that is shown after selecting contracts
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Alert from '../../components/popups/Alert';
import YoutubeVideo from '../../components/elements/YoutubeVideo';
import pageUrl from '../../const/pages';
import config from '../../config';
import trans from '../../trans';
import '../../styles/onboarding.scss';

const ContractListFinishPage = observer(() => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //start adding new user
    const next = () => {
        navigate(pageUrl.onboardingUserData);
    };

    return (
        <div className="app onboarding-contract-list-finish-page">
            <Header paddingBottom={20} expertMsg={trans.t('onboardingContractListFinishPage', 'expertMsg')} isBack>
                <div className="header-texts">
                    <div className="header-text">{trans.t('onboardingContractListFinishPage', 'headerText1')}</div>
                    <div className="header-text">{trans.t('onboardingContractListFinishPage', 'headerText2')}</div>
                    <div className="header-text">{trans.t('onboardingContractListFinishPage', 'headerText3')}</div>
                </div>
            </Header>
            <div className="content">
                <div className="wrapper">
                    <h1>{trans.t('onboardingContractListFinishPage', 'h1')}</h1>
                    <YoutubeVideo videoId={config.youtube.onboardingInfo} width={733} height={417} autoplay={0} />
                </div>
            </div>
            <Bottom>
                <Button role="btnNext" text={trans.t('onboardingContractListFinishPage', 'btnNext')} onClick={next} isRightIcon />
            </Bottom>
            <BottomLinks />
            <Alert message={trans.t('onboardingContractListFinishPage', 'successMsg')} />
        </div>
    );
});

export default ContractListFinishPage;
