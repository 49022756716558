/**
 * Created by Max Gornostayev on 02/15/22
 *
 * This is a logic for sending request to the BrokerCRM server using generating authToken and refreshToken from server
 * check this table where this logic is used https://docs.google.com/spreadsheets/d/1vzmpXE86s7Wr9V5DujNdxIIPWh7KVsHngBGbaZvVw2Y
 */

import API from './API';
import JwtAPI from './JwtAPI';
import codes from '../const/codes';
import config from '../config';
import trans from '../trans';

class AuthAPI extends API {
    static instance;
    authToken: '';
    refreshToken: '';

    static getInstance() {
        if (!AuthAPI.instance) {
            AuthAPI.instance = new AuthAPI();
        }
        return AuthAPI.instance;
    }

    constructor() {
        super({ baseURL: process.env.REACT_APP_API_URL, contentType: 'application/json' });

        super.setHeaderCommon('auth-app-name', 'fksdev');
        super.setHeaderCommon('auth-timestamp', 1647348402771);
    }

    /*
     * This is a function that sends a request to the BrokerCRM server
     *
     * @param method - string - method of the request like POST, GET, etc
     * @param version - string - version of the endpoint
     * @param url - string - the path of endpoint
     * @param data - object - params of the body {'name': value, ...}
     * @param params - object - params of the body {'name': value, ...}
     * @param skipRefreshing - bool - should we skip step for authorization or no
     * @param timeout - number - timeout of the request
     * @return object of the response, check super.getResponseObj
     */
    async request(method, version, url, data, params, skipRefreshing, timeout) {
        url = '/api/private/v' + version + url;

        timeout = timeout || config.api.timeout;

        //forming params for the request
        const conf = {
            method,
            url,
            timeout,
        };
        if (data) conf.data = data;
        if (params) conf.params = params;

        //check if token is existed, if not return not auth response
        if (!this.getAuthToken()) {
            return this.getNotAuthorizeReponse();
        }

        //try to get first response
        let response = await super.sendRequest(conf);

        // if bearer token is expired, try to get new token with refreshToken, if it's existing and send a request again
        if (this.getRefreshToken() && response.code === codes.statusNotAuthorize && !skipRefreshing) {
            const isAuth = await this.updateAuthTokenByRefreshToken();
            if (!isAuth) {
                this.removeTokens();
                return this.getNotAuthorizeReponse();
            }
            response = await super.sendRequest(conf);
        }

        // process a response and return a data if it's possible
        return response;
    }

    /*
     * get token from crm broker server
     */
    async updateAuthTokenByRefreshToken() {
        const res = await JwtAPI.request('get', '1', '/token/refresh', null, { refreshToken: this.getRefreshToken() });
        if (res.status) {
            this.updateTokens(res.data.authToken, res.data.refreshToken);
        }
        return res.status;
    }

    /*
     * get status of not authorize response, when you have mistake during getting bearer token from server
     */
    getNotAuthorizeReponse() {
        return super.getResponseObj(false, codes.statusNotAuthorize, {}, trans.t('codeResponsesAuthAPI', 'statusNotAuthorize'), 'noAuth');
    }

    /*
     * update token in axios instance
     */
    updateTokens(authToken, refreshToken) {
        this.setHeaderCommon('Authorization', `Bearer ${authToken}`);
        this.authToken = authToken;
        this.refreshToken = refreshToken || '';
    }

    /*
     * remove token in axios instance
     */
    removeTokens() {
        this.setHeaderCommon('Authorization', '');
        this.authToken = '';
        this.refreshToken = '';
    }

    /*
     * get token
     */
    getAuthToken() {
        return this.authToken;
    }

    /*
     * get token
     */
    getRefreshToken() {
        return this.refreshToken;
    }
}

export default AuthAPI.getInstance();
