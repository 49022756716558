/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingProducts
 * path: /onboarding/vertrag/extra-typ
 *
 * this is a page show products list to select product
 */

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import SearchInput from '../../components/elements/SearchInput';
import CheckboxList from '../../components/forms/CheckboxList';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/onboarding.scss';

const ProductsPage = observer(({ DataStore, ContractsStore, UserStore }) => {
    //get contract that will be added/updated from store
    const { contractTemp } = ContractsStore;
    const { productTypeValue } = contractTemp;
    const { isCompany } = UserStore;

    //state variables
    const [productType, setProductType] = useState(productTypeValue);
    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //for getting prop 'contractId' from another screen
    const { state } = useLocation();

    //component did mount handler
    useEffect(() => {
        if (!loaded) {
            const arr = DataStore.getProductsListByFilter('', isCompany);
            setProducts(arr);
            setLoaded(true);
        }
    }, [loaded, setLoaded]);

    //load products from store
    const loadProducts = (val) => {
        const arr = DataStore.getProductsListByFilter(val, isCompany);
        setProducts(arr);
    };

    //handling next button click
    const next = () => {
        ContractsStore.setProductTypeForAddingContract(productType);

        //determine is we edit the contract and send contractId to next page where it will be used
        const contractId = state && state.hasOwnProperty('contractId') && state.contractId;

        navigate(pageUrl.onboardingProviders, { state: { contractId } });
    };

    return (
        <div className="app onboarding-products-page">
            <Header isBack>
                <div>
                    <h1>{trans.t('onboardingProductsPage', 'h1')}</h1>
                    <SearchInput onChange={(val) => loadProducts(val)} />
                </div>
            </Header>
            <div className="content">
                {!!productType && (
                    <div className="wrapper-big">
                        <div className="selected-item">
                            <div className="wrapper">
                                <span>
                                    {trans.t('onboardingProductsPage', 'selectedProductText')}: {DataStore.getProductTypeTitle(contractTemp.productTypeValue)}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="wrapper">
                    <CheckboxList list={products} onClick={(val) => setProductType(val)} defaultValue={productType} />
                </div>
            </div>
            {productType && (
                <Bottom>
                    <Button role="btnNext" text={trans.t('onboardingProductsPage', 'btnNext')} onClick={next} isRightIcon />
                </Bottom>
            )}
            <BottomLinks />
        </div>
    );
});

export default ProductsPage;
