/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a button component
 *
 * props:
 * @onClick - func - function that handler onClick
 * @isRightIcon - bool - sho right icon or no
 * @isWhite - bool - is white theme
 * @isTransparent - bool - is transparent bg or no
 * @isLeftAlign - bool - is title left align
 * @isFullWidth - bool - is button use full width of the parent
 * @className - string - custom class name
 * @height - int - custom height of the button
 * @leftIcon - source - source of the left icon
 * @role - string - id for testing
 * @text - string - title
 * @info - string - show info text or no
 */

export default function Button(props) {
    //on lick handler when click on the link
    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    //prepare class name for container
    let className = 'button';
    const style = {};
    if (props.isRightIcon) {
        className += props.isWhite ? ' right-icon-black' : ' right-icon';
    }
    if (props.isWhite) {
        className += ' white';
    }
    if (props.isTransparent) {
        className += ' transparent';
    }
    if (props.isLeftAlign) {
        className += ' left-align';
    }
    if (props.isFullWidth) {
        className += ' full-width';
    }
    if (props.marginNone) {
        className += ' margin-none';
    }
    if (props.paddingNone) {
        className += ' padding-none';
    }
    if (props.className) {
        className += ' ' + props.className;
    }
    if (props.height) {
        style.height = props.height + 'px';
    }

    return (
        <div className={className} style={style} onClick={onClick} role={props.role}>
            <div className="inner">
                {props.leftIcon && (
                    <div className="left-icon">
                        <img src={props.leftIcon} alt="" />
                    </div>
                )}
                <div className="text">
                    {props.text}
                    {!!props.info && <div className="info">{props.info}</div>}
                </div>
            </div>
        </div>
    );
}
