/**
 * Created by Max Gornostayev on 2/28/21
 *
 * MODEL - parent class for customer and contract models
 */

class Main {
    /*
     * constructor
     * @var obj - object that contains values of the model
     * @var model - child model that should be used
     */
    constructor(obj, model) {
        const fields = this.getFields(obj, model);

        Object.assign(this, fields);
    }

    /*
     * get server object from model
     */
    getServerObject(model) {
        const options = JSON.parse(JSON.stringify(model));
        Object.keys(options).forEach((key) => {
            if (typeof options[key] === 'object') {
                Object.keys(options[key]).forEach((field) => {
                    options[key][field] = this[field] ? this[field] : options[key][field];
                });
            } else {
                options[key] = this[key] || options[key];
            }
        });

        return options;
    }

    /*
     * set fields object
     */
    getFields(obj, model) {
        const defaultOptions = JSON.parse(JSON.stringify(model));
        if (obj) {
            Object.keys(defaultOptions).forEach((key) => {
                if (typeof defaultOptions[key] === 'object') {
                    Object.keys(defaultOptions[key]).forEach((field) => {
                        defaultOptions[key][field] = obj.hasOwnProperty(key) && obj[key].hasOwnProperty(field) ? obj[key][field] : defaultOptions[key][field];
                    });
                } else {
                    defaultOptions[key] = obj.hasOwnProperty(key) ? obj[key] : defaultOptions[key];
                }
            });
        }

        const fields = {};
        Object.keys(defaultOptions).forEach((key) => {
            if (typeof defaultOptions[key] === 'object') {
                Object.keys(defaultOptions[key]).forEach((field) => {
                    fields[field] = defaultOptions[key][field];
                });
            } else {
                fields[key] = defaultOptions[key];
            }
        });
        return fields;
    }
}

export default Main;
