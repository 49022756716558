/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show provider field
 */

import trans from '../../trans';

export default function ProviderField({ title, onClick }) {
    return (
        <div className="provider-element" onClick={onClick}>
            <div className="title">{trans.t('elements', 'providerTitle')}</div>
            <div className="text">{title}</div>
        </div>
    );
}
