/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a alert that show alert messages to inform user about mistakes or success
 *
 * TODO: Fix legacy error, using this comment https://github.com/rnosov/react-reveal/issues/116
 * TODO: Fix error in RevealBase when do `yarn test`
 */

import React from 'react';
import PropTypes from 'prop-types';
import { v4 as getUid } from 'uuid';
import { timer } from 'rxjs';
// import Fade from 'react-reveal/Fade';
// import TransitionGroup from 'react-transition-group/TransitionGroup';
import DateUtil from '../../lib/DateUtil';
import IconClose from '../../assets/icon-close-white.png';

class Alert extends React.Component {
    /*
     * constructor
     */
    constructor(props) {
        super(props);

        const messages = {};
        if (this.props.message) {
            const msgObj = this.getMessageObject(this.props.message, this.props.type);
            messages[msgObj.uid] = msgObj;
        }
        this.state = { messages };
        // this.groupProps = {
        //     appear: false,
        //     enter: true,
        //     exit: true,
        // };
    }

    /*
     * executed when component will be destroyed, and we need to remove all subscriptions
     */
    componentWillUnmount() {
        const { messages } = this.state;
        Object.keys(messages).map((uid) => {
            if (messages.hasOwnProperty(uid)) {
                if (messages[uid].subscription && messages[uid].subscription.unsubscribe) messages[uid].subscription.unsubscribe();
            }
        });
    }

    /*
     * get message object
     * message - text
     * type - string which can be ['error', 'success', 'warning']
     */
    getMessageObject = (message, type) => {
        const uid = `${getUid()}-${DateUtil.timestamp()}`;
        type = type || 'success';
        const subscription = timer(15000).subscribe(() => this.hide(uid, true));

        return { uid, message, type, subscription };
    };

    /*
     * show new message
     * message - text
     * type - string which can be ['error', 'success', 'warning']
     */
    show = (message, type) => {
        const { messages } = this.state;

        const isMessageExist = Object.keys(messages).find((key) => messages[key].message === message);
        if (!message || isMessageExist) return null;

        const msgObj = this.getMessageObject(message, type);
        messages[msgObj.uid] = msgObj;

        this.setState({ messages });
    };

    /*
     * show error message
     */
    error = (message) => this.show(message, 'error');

    /*
     * hide message
     */
    hide = (uid, isAuto) => {
        const { messages } = this.state;
        if (this.check(uid)) {
            if (!isAuto && messages[uid].subscription && messages[uid].subscription.unsubscribe) messages[uid].subscription.unsubscribe();
            if (this.check(uid)) {
                delete messages[uid];
                if (this && this.setState) {
                    this.setState({ messages });
                }
            }
        }
    };

    /*
     * checking is current message existed
     */
    check = (uid) => this && this.state.messages.hasOwnProperty(uid);

    /*
     * render item
     */
    // renderItem = (message) => (
    //     <Fade key={message.uid} collapse>
    //         <div className={'item ' + message.type}>
    //             <div className="message">{message.message}</div>
    //             <div className="close" onClick={() => this.hide(message.uid)}>
    //                 <img src={IconClose} alt="" />
    //             </div>
    //         </div>
    //     </Fade>
    // );
    renderItem = (message) => (
        <div key={message.uid} className={'item ' + message.type} role="alertItem">
            <div className="message">{message.message}</div>
            <div className="close" onClick={() => this.hide(message.uid)}>
                <img src={IconClose} alt="" />
            </div>
        </div>
    );

    /*
     * rendering
     */
    render() {
        const { messages } = this.state;
        // return (
        //     <div className="alerts">
        //         <TransitionGroup {...this.groupProps}>
        //             {Object.values(messages).map((message) =>
        //                 this.renderItem(message)
        //             )}
        //         </TransitionGroup>
        //     </div>
        // );
        return (
            <div className="alerts" role="alertsContainer">
                {Object.values(messages).map((message) => this.renderItem(message))}
            </div>
        );
    }
}

Alert.defaultProps = {
    message: '',
    type: 'success',
};
Alert.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
};

export default Alert;
