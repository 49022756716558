/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a checkbox
 */

import React, { useState } from 'react';

export default function Checkbox({ onClick, children, isChecked, role, title, value, className, isDisable }) {
    const [checked, setChecked] = useState(!!isChecked);

    const onSelect = () => {
        if (!isDisable) {
            const newChecked = !checked;
            setChecked(newChecked);

            if (onClick) {
                const val = value || true;
                onClick(newChecked ? val : false);
            }
        }
    };

    let clssName = 'checkbox';
    if (className) {
        clssName += ' ' + className;
    }
    if (isDisable) {
        clssName += ' disable  ';
    }

    return (
        <div className={clssName}>
            <div className="checkbox-inner" onClick={onSelect} role={role}>
                <div className={checked ? 'element checked' : 'element'} />
            </div>
            {title ? <div className="title">{title}</div> : ''}
            {children}
        </div>
    );
}
