const objModel = {
    id: '',
    title: '',
    serviceplatformId: '',
    isBrokerPossible: false,
    finapiId: '',
    categories: [],
    status: '',
};

export default objModel;
