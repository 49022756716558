/**
 * Created by Max Gornostayev on 05/25/22
 *
 * pageId: secureDashboard
 * path: /dashboard
 *
 * this is a main page that user see after success login
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import HeaderSecure from '../../components/theme/HeaderSecure';
import ButtonDashboard from '../../components/buttons/ButtonDashboard';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import config from '../../config';
import trans from '../../trans';
import '../../styles/secure.scss';

import IconTodo from '../../assets/icon-todo.svg';
import IconCoins from '../../assets/icon-coins.svg';
import IconQR from '../../assets/icon-qr.svg';

const DashboardPage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get current customer
    const { customer } = UserStore;
    //main rendering
    return (
        <div className="app secure-dashboard-page">
            <HeaderSecure selected={pageUrl.secureDashboard} isTopMenu isSlideMenu>
                <div className="wrapper-big">
                    <h3>{trans.tParams('secureDashboardPage', 'titleHeader', { name: customer.firstNameValue })}</h3>
                    <div className="row-spacebetween-center">
                        <div className="row-left-center">
                            <ButtonDashboard
                                icon={IconTodo}
                                titleNumber={ContractsStore.getNumberOfContracts + ' '}
                                titleText={trans.t('secureDashboardPage', 'contracts')}
                                onClick={() => navigate(pageUrl.secureContractsList)}
                            />
                            <ButtonDashboard icon={IconCoins} titleNumber={ContractsStore.getPayments + ' EUR'} titleText={trans.t('secureDashboardPage', 'payments')} />
                        </div>
                        <ButtonDashboard icon={IconQR} titleText={trans.t('secureDashboardPage', 'qrText')} onClick={() => navigate(pageUrl.secureReferral)} />
                    </div>
                </div>
            </HeaderSecure>
            <div className="content">
                <div className="wrapper-big">
                    <div className="row-spacebetween-center">
                        <h3>{trans.t('secureDashboardPage', 'titleTicket')}</h3>
                        <Button text={trans.t('secureDashboardPage', 'btnTitle')} onClick={() => window.open(config.urls.newTicket, '_blank').focus()} isRightIcon role="btnNext" />
                    </div>
                    <div className="text">{trans.t('secureDashboardPage', 'textTicket')}</div>
                </div>
            </div>
        </div>
    );
});

export default DashboardPage;
