/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show loading animation
 */

import ReactLoading from 'react-loading';

export default function Loading({ isShow }) {
    if (!isShow) {
        return null;
    }
    return (
        <div className="loading">
            <ReactLoading type="spin" color="#234557" />
        </div>
    );
}
