/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: authConfirmEmail
 * path: /auth/email/confirm?token={emailConfirmToken}
 *
 * this is a success page that will be shown after loggedin customer clicks on the link of the letter that this customer gets
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/onboarding.scss';

const ConfirmEmailPage = observer(({ UserStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get search query object
    const [searchParams] = useSearchParams();

    useEffect(async () => {
        const emailToken = searchParams.get('token');
        if (emailToken) {
            await UserStore.emailConfirm(emailToken);
        }
    }, []);

    //button handler
    const next = () => {
        navigate(UserStore.isAuthorized ? pageUrl.secureDashboard : pageUrl.authSigninPage);
    };

    //main rendering
    return (
        <div className="app general-message-page">
            <Header expertTitle={trans.t('confirmEmailPage', 'expertTitle')} expertMsg={trans.t('confirmEmailPage', 'expertMsg')} expertClass="expert-info">
                <div className="wrapper-vsmall">
                    <Button role="btnNext" text={trans.t('confirmEmailPage', 'btnTitle')} onClick={next} isRightIcon isWhite isFullWidth className="margin-top-32" />
                </div>
            </Header>
            <BottomLinks isBgBlue />
        </div>
    );
});

export default ConfirmEmailPage;
