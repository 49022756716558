/**
 * Created by Max Gornostayev on 2/28/22
 *
 * MODEL - broker status
 */

import Main from './Parent';
import model from './fields/brokerStatus';
import syncLists from '../../const/syncLists';

class BrokerStatus extends Main {
    constructor({ obj, syncObj }) {
        if (syncObj) {
            obj = Main.transformSyncObj(syncObj, syncLists.brokerStatus);
        }
        super(obj, model);
    }

    get titleValue() {
        return this.title;
    }

    get idValue() {
        return this.serviceplatformId || this.id || '';
    }

    get serviceplatformIdValue() {
        return this.serviceplatformId || '';
    }
}

export default BrokerStatus;
