/**
 * Created by Max Gornostayev on 2/28/22
 *
 * MODEL - customer model
 */
import { v4 as getUid } from 'uuid';
import Main from './Parent';
import model from './fields/customer';
import countries from '../../const/countries';
import DateUtil from '../../lib/DateUtil';
import API from '../../api';
import trans from '../../trans';

class Customer extends Main {
    constructor(obj) {
        super(obj, model);

        this.affiliateID = '';
        this.referalID = '';
        this.isCompanyValue = false;
        this.setTempId();
    }

    /*
     * get server object from model
     */
    get serverObject() {
        const obj = super.getServerObject(model);
        return obj;
    }

    get id() {
        const tempId = this.customerTempID ? this.customerTempID : null;
        return this.customerId ? this.customerId : tempId;
    }

    get salutationValue() {
        return this.salutation ? this.salutation : '';
    }

    get firstNameValue() {
        return this.firstName ? this.firstName : '';
    }

    get lastNameValue() {
        return this.lastName ? this.lastName : '';
    }

    get birthdateValue() {
        return this.birthdate ? DateUtil.getFormatDate(this.birthdate) : '';
    }

    get addressValue() {
        return this.street ? this.street : '';
    }

    get postcodeValue() {
        return this.postalCode ? this.postalCode : '';
    }

    get cityValue() {
        return this.city ? this.city : '';
    }

    get emailValue() {
        return this.email ? this.email : '';
    }

    get phoneCodeValue() {
        return this.phoneCode ? this.phoneCode : countries.deutschland.code;
    }

    get phoneNumberValue() {
        return this.phoneNumber ? this.phoneNumber : '';
    }

    get phoneTitle() {
        return this.phoneCodeValue + this.phoneNumberValue;
    }

    get phoneCodeTitle() {
        const country = Object.values(countries).find((obj) => obj.code === this.phoneCodeValue);
        return country ? country.title : this.phoneCodeValue;
    }

    get isEmailVerified() {
        return !!this.emailVerified;
    }

    get isTemp() {
        return !this.customerId;
    }

    get fullName() {
        let fullName = '';
        if (this.firstName) {
            fullName += `${this.firstName} `;
        }

        if (this.lastName) {
            fullName += this.lastName;
        }
        return fullName;
    }

    get termsAgreeDate() {
        return this.acceptTermsOfUseAt || '';
    }

    get activatedBrokerDate() {
        return this.acceptPrivacyTermsAt ? DateUtil.getFormatDate(this.acceptPrivacyTermsAt) : '';
    }

    /*
     * determine - does the customer have a signed broker agreement
     */
    get isHaveBroker() {
        return !!this.activatedBrokerDate;
    }

    get salutationTitle() {
        return this.salutation ? this.salutation : '';
    }

    get positionValue() {
        return this.position || '';
    }

    get companyNameValue() {
        return this.companyName || '';
    }

    get isCompany() {
        return !!this.isCompanyValue;
    }

    /*
     * set fields object
     */
    setObject(fieldsObj) {
        Object.keys(fieldsObj).map((fieldName) => this.setValue(fieldName, fieldsObj[fieldName]));
    }

    /*
     * set value of param for the customer
     */
    setValue(id, value) {
        switch (id) {
            case 'birthdate':
                this.birthdate = value ? DateUtil.getFormatServerDate(value) : '';
                break;
            default:
                this[id] = value;
                break;
        }
        return null;
    }

    /*
     * set referal ids
     */
    setAffiliateIds({ affiliateID, referalID }) {
        this.affiliateID = affiliateID || '';
        this.referalID = referalID || '';
    }

    /*
     * set id
     */
    setId(value) {
        this.setValue('customerId', value);
    }

    /*
     * set temp id
     */
    setTempId() {
        this.setValue('customerTempID', getUid());
    }

    /*
     * set temp id
     */
    static setAuthTokens(authToken, refreshToken) {
        API.auth.setTokens(authToken, refreshToken);
    }

    /*
     * save new customer
     */
    async create(fieldsObj, isCompany) {
        this.setTempId();
        this.setObject(fieldsObj);
        const obj = this.serverObject;
        obj.affiliate = { affiliateID: this.affiliateID, referalID: this.referalID };
        if (this.externalId) {
            obj.customer.externalId = this.externalId;
        }
        this.isCompanyValue = isCompany;
        if (!isCompany) {
            delete obj.company;
            delete obj.contactPerson;
        } else {
            obj.contactPerson = { ...obj.customer };
            delete obj.customer;
        }
        const res = await API.customer.create(obj, isCompany);
        if (res.status) {
            this.setId(res.data.customer.customerId);
        }
        return res;
    }

    /*
     * save new customer
     */
    async update(fieldsObj) {
        this.setObject(fieldsObj);
        const obj = this.serverObject;
        if (this.isCompany) {
            delete obj.customer;
        } else {
            delete obj.customer.customerId;
            delete obj.customer.customerTempID;
            delete obj.company;
            delete obj.contactPerson;
        }
        const res = await API.customer.update(obj);
        return res;
    }

    /*
     * load customer from server
     */
    async load() {
        const res = await API.customer.get();
        if (res.status) {
            const newModel = JSON.parse(JSON.stringify(model));
            if (res.data.hasOwnProperty('company')) {
                delete newModel.customer;
                this.isCompanyValue = true;
            } else {
                delete newModel.company;
                delete newModel.contactPerson;
                this.isCompanyValue = false;
            }
            const fields = super.getFields(res.data, newModel);
            Object.keys(fields).map((fieldName) => {
                this[fieldName] = fields[fieldName];
            });
        }
        return res;
    }

    /*
     * send OTP request
     */
    async sendOTP() {
        if (!this.isTemp) {
            const obj = {
                email: this.emailValue,
                phoneCode: this.phoneCodeValue,
                phoneNumber: this.phoneNumberValue,
                customerId: this.id,
            };
            const res = await API.auth.sendOTP(obj);
            return res;
        }

        return { status: false, msg: trans.t('errors', 'customerNotExisted') };
    }

    /*
     * send OTP confirm request
     */
    async sendOTPConfirm(code) {
        if (!this.isTemp) {
            const obj = {
                code,
                phoneCode: this.phoneCodeValue,
                phoneNumber: this.phoneNumberValue,
                customerId: this.id,
            };
            const res = await API.auth.sendOTPConfirm(obj);
            return res;
        }

        return { status: false, msg: trans.t('errors', 'customerNotExisted') };
    }

    /*
     * send Email confirm request
     */
    async sendEmailConfirmation(code) {
        const res = await API.auth.sendEmailConfirmation(code);
        return res;
    }

    /*
     * send OTP confirm request
     */
    async signBroker({ signBrokerBase64, signPrivacyBase64, isContactingAccepted, isNewServiceAccepted, isMarketingAccepted, contractIds }) {
        if (!this.isTemp) {
            const obj = {
                customerId: this.id,
                brokerSign: signBrokerBase64,
                privacySign: signPrivacyBase64,
                isContactingAccepted: !!isContactingAccepted,
                isNewServiceAccepted: !!isNewServiceAccepted,
                isMarketingAccepted: !!isMarketingAccepted,
                contractIds: contractIds || [],
            };
            const res = await API.customer.signBroker(obj);
            if (res.status) {
                // update customer with data that comes
                // "data": {
                //     "customer": {
                //         "brokerAgreementId": "1224144",
                //             "brokerServiceStartedAt":
                //         "2019-08-24T14:15:22Z",
                //             "brokerServiceStartedAt":
                //         "2019-08-24T14:15:22Z",
                //             "externalId": "155662"
                //     }
                // }
            }
            return res;
        }

        return { status: false, msg: trans.t('errors', 'customerNotExisted') };
    }

    /*
     * update password of the customer
     * @param password - string
     * @param passwordToken - string
     */
    static async updatePassword(password, passwordToken) {
        const res = await API.auth.updatePassword(password, passwordToken);
        return res;
    }

    /*
     * confirm email with token
     * @param emailToken - string
     */
    async emailConfirm(emailToken) {
        const res = await API.auth.sendEmailConfirm(emailToken);
        if (res.status) {
            this.emailVerified = true;
        }
        return res;
    }

    /*
     * do login with phone
     * @param phoneCode - string
     * @param phoneNumber - string
     * @param password - string
     */
    static async loginWithPhoneAndPass(phoneCode, phoneNumber, password) {
        const res = await API.auth.loginWithPhoneAndPass(phoneCode, phoneNumber, password);
        return res;
    }

    /*
     * do login with email
     * @param email - string
     * @param password - string
     */
    static async loginWithEmailAndPass(email, password) {
        const res = await API.auth.loginWithEmailAndPass(email, password);
        return res;
    }

    /*
     * get broker agreement files
     */
    static async getBrokerAgreementFiles() {
        const res = await API.customer.getBrokerAgreement();
        return res;
    }

    /*
     * send email with reseting link
     */
    static async sendForgotEmail(email) {
        const res = await API.auth.sendForgotEmail(email);
        return res;
    }

    /*
     * confirm password when reseting the pass
     */
    static async sendForgotEmailConfirm(password, code) {
        const res = await API.auth.sendForgotEmailConfirm(password, code);
        return res;
    }
}

export default Customer;
