/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is parent class for API requests
 */

import axios from 'axios';
import codes from '../const/codes';
import trans from '../trans';

class API {
    axiosInstance = null;
    isDebug = false;
    baseURL = '';

    constructor(obj) {
        this.setAxiosInstance(obj);
    }

    /*
     * send request
     */
    setAxiosInstance({ baseURL, contentType, xRequestedWith }) {
        const params = { headers: {} };
        if (baseURL) {
            params.baseURL = baseURL;
            this.baseURL = baseURL;
        }

        params.headers['Content-Type'] = contentType || 'application/x-www-form-urlencoded';
        params.headers['X-Requested-With'] = xRequestedWith || 'XMLHttpRequest';

        this.axiosInstance = axios.create(params);

        delete this.axiosInstance.defaults.headers.common['X-Requested-With'];

        this.axiosInstance.interceptors.response.use(
            (response) => {
                if (this.isDebug) {
                    // eslint-disable-next-line
                    console.log('\n\n', response, '\n\n');
                }
                return response;
            },
            (error) => {
                if (this.isDebug) {
                    // eslint-disable-next-line
                    console.log('\n\n', error, '\n\n');
                }
                return Promise.reject(error);
            }
        );
    }

    /*
     * set debug mode
     */
    setDebug(value) {
        this.isDebug = value;
    }

    /*
     * send request
     */
    sendRequest(requestParams) {
        return this.axiosInstance
            .request(requestParams)
            .then((response) => this.success(response))
            .catch((e) => this.error(e));
    }

    /*
     * proccess a response if it's success
     */
    success(response) {
        if (!response.hasOwnProperty('status')) {
            return this.getResponseObj(false);
        }
        let data = response.data || {};
        if (data.data) {
            data = data.data;
        }
        return this.getResponseObj(true, response.status, data);
    }

    /*
     * proccess a response if it's error
     */
    error(error) {
        if (!error.response) {
            //process timeout
            return this.getResponseObj(false, codes.statusTimeout, {}, '', 'timeout');
        }
        let code = error.response.hasOwnProperty('status') ? error.response.status : null;
        let data = error.response.hasOwnProperty('data') ? error.response.data : null;
        if (data && data.data) {
            data = data.data;
        }
        if (!code && data && data.hasOwnProperty('code')) {
            code = data.code;
        }
        const msg = this.getErrorMsg(code, data);
        const errorId = data && data.id ? data.id : '';
        return this.getResponseObj(false, code, data, msg, errorId);
    }

    /*
     * post proccess a response
     */
    getErrorMsg(code, data) {
        let msg = data && data.id ? trans.t('serverErrorIds', data.id, '') : '';
        if (!msg) {
            switch (code) {
                case codes.statusTimeout:
                    msg = trans.t('codeResponsesAPI', 'statusTimeout');
                    break;
                case codes.statusNotAuthorize:
                    msg = trans.t('codeResponsesAPI', 'statusNotAuthorize');
                    break;
                case codes.statusBadRequest:
                case codes.statusNotFound:
                    msg = trans.t('codeResponsesAPI', 'statusBadRequest');
                    break;
                case codes.statusInternalError:
                case codes.statusInternalServerError:
                default:
                    msg = trans.t('codeResponsesAPI', 'statusInternalServerError');
                    break;
            }
        }
        return msg;
    }

    /*
     * form response object
     * @param status - bool - status of the response
     * @param code - string - code of the response
     * @param data - object - that is returned
     * @param msg - string - message of the response
     * @errorId msg - string - errorId of the response if existed
     * @return object
     */
    getResponseObj(status, code, data, msg, errorId) {
        code = code || codes.statusInternalError;
        code = code.toString();
        data = data || {};
        msg = msg || '';
        errorId = errorId || '';
        const retObj = { status, code, data, msg, errorId };
        return retObj;
    }

    /*
     * set common header in axios instance
     */
    setHeaderCommon(id, value) {
        this.axiosInstance.defaults.headers.common[id] = value;
    }
}

export default API;
