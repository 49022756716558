/**
 * Created by Max Gornostayev on 3/17/22
 *
 * Validation logic
 *
 */

import validator from 'email-validator';
import trans from '../trans';
import config from '../config';
import countries from '../const/countries';
import Utils from './Utils';
import DateUtil from './DateUtil';

const validationReturnValue = (validated, msg) => ({ validated, msg });

const Validation = {
    job: (val) => {
        const patt = /^[a-zA-Z ]{2,}$/g;
        return validationReturnValue(patt.test(val), trans.t('validations', 'validationJob'));
    },

    nationality: (val) => {
        const patt = /^[a-zA-Z ]{2,}$/g;
        return validationReturnValue(patt.test(val), trans.t('validations', 'validationNationality'));
    },

    birthdate: (val) => {
        if (!val || !DateUtil.isDateValid(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationBDEmpty'));
        }
        const diff = DateUtil.getDiffYearFromNow(val, config.dateFormat.date);
        if (diff < 18) {
            return validationReturnValue(false, trans.t('validations', 'validationBD18'));
        }
        if (diff > 90) {
            return validationReturnValue(false, trans.t('validations', 'validationBD90'));
        }
        return validationReturnValue(true);
    },

    // mask for email - /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
    email: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationEmailEmpty'));
        }
        if (!validator.validate(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationEmailNotValid'));
        }
        return validationReturnValue(true);
    },

    phone: (val, code, checkPhones, listPhones, msg) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationPhoneEmpty'));
        }

        val = val.toString();

        let patt = /^\d+$/g;
        if (!patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationPhoneNotValid'));
        }
        patt = /^[0]/;
        if (patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationPhoneNotZeroStart'));
        }

        if (checkPhones && listPhones.indexOf(val) !== -1) {
            return validationReturnValue(false, msg);
        }

        // eslint-disable-next-line
        switch (code) {
            case countries.switzerland.code:
            case countries.liechtenstein.code:
                patt = /^[7]/;
                if (!patt.test(val)) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneStart7'));
                }
                if (val.length !== 9) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneLength9'));
                }
                break;
            case countries.deutschland.code:
                patt = /^[1]/;
                if (!patt.test(val)) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneStart1'));
                }
                if (val.length < 8) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneLength5'));
                }
                if (val.length > 12) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneLength12'));
                }
                break;
            case countries.austrian.code:
                patt = /^[6]/;
                if (!patt.test(val)) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneStart6'));
                }
                if (val.length !== 8) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneLength8'));
                }
                break;
            case countries.italian.code:
                patt = /^[3]/;
                if (!patt.test(val)) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneStart3'));
                }
                if (val.length !== 10) {
                    return validationReturnValue(false, trans.t('validations', 'validationPhoneLength10'));
                }
                break;
        }

        return validationReturnValue(true);
    },

    password: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordEmpty'));
        }
        let patt = /[A-Z]/;
        if (!val.match(patt)) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordNotValid'));
        }
        patt = /[a-z]/;
        if (!val.match(patt)) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordNotValid'));
        }
        patt = /[0-9]/;
        const pattSpecial = /[!@#$%^&*]/;
        if (!val.match(patt) && !val.match(pattSpecial)) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordNotValid'));
        }
        if (val.length < 8) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordNotValid'));
        }
        return validationReturnValue(true);
    },

    passwordConfirm: (confirm, pass) => {
        if (!confirm) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordConfirmEmpty'));
        }
        if (confirm !== pass) {
            return validationReturnValue(false, trans.t('validations', 'validationPasswordConfirmNotValid'));
        }
        return validationReturnValue(true);
    },

    freebeeCode: (val) => {
        if (val.length !== 10) {
            return validationReturnValue(false, trans.t('validations', 'validationFreebeeCodeLength10'));
        }
        return validationReturnValue(true);
    },

    iban: (val) => {
        const patt = /^[0-9]{2}/;
        if (val.length > 34 || val.length < 15 || patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationIBANNotValid'));
        }
        return validationReturnValue(true);
    },

    firstName: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationFirstNameEmpty'));
        }

        if (val.length < 2) {
            return validationReturnValue(false, trans.t('validations', 'validationFirstNameLength'));
        }

        const patt = /[0-9]/;
        if (patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationFirstNameNotNumbers'));
        }
        return validationReturnValue(true);
    },

    lastName: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationLastNameEmpty'));
        }

        if (val.length < 2) {
            return validationReturnValue(false, trans.t('validations', 'validationLastNameLength'));
        }

        const patt = /[0-9]/;
        if (patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationLastNameNotNumbers'));
        }
        return validationReturnValue(true);
    },

    postcode: (val, code) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationPostcodeEmpty'));
        }

        val = val.toString();
        let numberDigits = null;
        let msg = '';
        // eslint-disable-next-line
        switch (code) {
            case countries.switzerland.code:
            case countries.austrian.code:
                numberDigits = 4;
                msg = trans.t('validations', 'validationPostcodeLength4');
                break;
            case countries.deutschland.code:
                numberDigits = 5;
                msg = trans.t('validations', 'validationPostcodeLength5');
                break;
        }
        if (numberDigits && msg && val.length !== numberDigits) {
            return validationReturnValue(false, msg);
        }
        return validationReturnValue(true);
    },

    price: (val, errorMsgEmpty, errorMsgValidation) => {
        errorMsgEmpty = errorMsgEmpty || trans.t('validations', 'validationPriceEmpty');
        if (!val) {
            return validationReturnValue(false, errorMsgEmpty);
        }
        errorMsgValidation = errorMsgValidation || trans.t('validations', 'validationPriceNotValid');
        // eslint-disable-next-line
        const patt = /^\d+\.{0,1}\d{0,2}?$/g;
        if (!patt.test(Utils.priceProcess(val))) {
            return validationReturnValue(false, errorMsgValidation);
        }
        return validationReturnValue(true);
    },

    yearlySpending: (val) => Validation.price(val, trans.t('validations', 'validationYearlySpendingEmpty'), trans.t('validations', 'validationYearlySpendingNotValid')),

    yearlySpendingCost: (val) => Validation.price(val, trans.t('validations', 'validationYearlySpendingCostEmpty'), trans.t('validations', 'validationYearlySpendingCostNotValid')),

    document: (filetype, filesize) => {
        if (parseInt(filesize, 10) > 20971520) {
            return validationReturnValue(false, trans.t('validations', 'validationFilesizeIsBig'));
        }

        const validationTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (validationTypes.indexOf(filetype.toLowerCase()) === -1) {
            return validationReturnValue(false, trans.t('validations', 'validationFiletypeIsWrong'));
        }
        return validationReturnValue(true);
    },

    // eslint-disable-next-line
    startDate: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationStartDateEmpty'));
        }
        return validationReturnValue(true);
    },

    endDate: (val, startDate) => {
        if (val) {
            const diff = DateUtil.getDiffDaysFromDate(val, startDate, config.dateFormat.date);
            if (diff > 0) {
                return validationReturnValue(false, trans.t('validations', 'validationEndDate'));
            }
        }
        return validationReturnValue(true);
    },

    address: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationAddressEmpty'));
        }

        const patt = /[0-9]/;
        if (!patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationAddressContainNumbers'));
        }

        return validationReturnValue(true);
    },

    addressWithoutNumber: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationAddressWithoutNumberEmpty'));
        }

        return validationReturnValue(true);
    },

    houseNumber: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationHouseNumberEmpty'));
        }

        const patt = /[0-9]/;
        if (!patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationHouseNumberContainNumbers'));
        }

        return validationReturnValue(true);
    },

    city: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationCityEmpty'));
        }

        const patt = /[0-9]/;
        if (patt.test(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationCityNotNumbers'));
        }

        return validationReturnValue(true);
    },

    paypal: (val) => {
        if (!val) {
            return validationReturnValue(false, trans.t('validations', 'validationPaypalEmailEmpty'));
        }
        if (!validator.validate(val)) {
            return validationReturnValue(false, trans.t('validations', 'validationPaypalEmailNotValid'));
        }
        return validationReturnValue(true);
    },

    emptyValue: (val, msg) => validationReturnValue(!!val, msg || ''),

    validated: (obj) => obj.validated,
};

export default Validation;
