/**
 * Created by Max Gornostayev on 3/17/22
 *
 * Date help library - functions for working with dates
 */

import moment from 'moment';
import config from '../config';

const DateUtils = {
    /*
     * get timestamp value
     */
    timestamp: (isMilliseconds) => parseInt(isMilliseconds ? moment().valueOf() : moment().unix(), 10),

    /*
     * get difference(in years) from current date and 'dateString' variable
     */
    getDiffYearFromNow: (dateString, fromformat) => {
        const now = moment();
        fromformat = fromformat || config.dateFormat.serverDate;
        const date = moment(dateString, fromformat);
        return now.diff(date, 'years', true);
    },

    /*
     * get difference(in days) from startDateString and dateString
     */
    getDiffDaysFromDate: (dateString, startDateString, fromformat) => {
        fromformat = fromformat || config.dateFormat.serverDate;
        const d = moment(startDateString, fromformat);
        const date = moment(dateString, fromformat);
        return d.diff(date, 'days', true);
    },

    /*
     * convert date js object into string with format
     */
    convertFromDate: (date, format) => {
        const d = moment(date || null);
        return d.format(format || config.dateFormat.date);
    },

    /*
     * get difference(in years) from current date and 'dateString' variable
     */
    isDateValid: (dateString, fromformat) => {
        const date = moment(dateString, fromformat || config.dateFormat.date);
        return date.isValid();
    },

    /*
     * convert date from 'fromformat' to 'toformat'
     */
    getFormatServerDate: (d, toformat, fromformat) =>
        d ? moment(d, fromformat || config.dateFormat.date).format(toformat || config.dateFormat.serverDate) : moment().format(toformat || config.dateFormat.serverDate),

    /*
     * get format date from 'fromformat' to 'toformat'
     */
    getFormatDate: (d, toformat, fromformat) =>
        d ? moment(d, fromformat || config.dateFormat.serverDate).format(toformat || config.dateFormat.date) : moment().format(toformat || config.dateFormat.date),

    /*
     * get year
     * @param d - string
     * @param fromformat - string, from what format
     */
    getYear: (d, fromformat) => {
        fromformat = fromformat || config.dateFormat.serverDate;
        const dateObj = d ? moment(d, fromformat) : moment();
        return dateObj.year();
    },

    /*
     * get countdown title
     */
    getCountdownTitle: (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        const secondTitle = seconds < 10 ? '0' + seconds : seconds;
        return minutes + ':' + secondTitle;
    },
};

export default DateUtils;
