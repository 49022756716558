/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component to scroll to top if its not at the start of the page
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
