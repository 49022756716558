/**
 * Created by Max Gornostayev on 2/28/22
 *
 * MODEL - provider
 */

import Main from './Parent';
import model from './fields/provider';
import syncLists from '../../const/syncLists';

class Provider extends Main {
    constructor({ obj, syncObj }) {
        if (syncObj) {
            obj = Main.transformSyncObj(syncObj, syncLists.provider);
        }
        super(obj, model);

        // if (this.products && typeof this.products === 'string') {
        //     this.products = this.products.split(', ');
        // }

        if (this.categories && typeof this.categories === 'string') {
            this.categories = this.categories.split(', ');
        }
    }

    get titleValue() {
        return this.title;
    }

    get idValue() {
        return this.serviceplatformId || this.id || '';
    }

    get finapiIdValue() {
        return this.finapiId || '';
    }

    get serviceplatformIdValue() {
        return this.serviceplatformId || '';
    }

    get isBroker() {
        return !!this.isBrokerPossible;
    }

    get logoUrl() {
        return this.logo || '';
    }

    /*
     * Check if productId is existed in products array
     */
    // checkProduct(productId) {
    //     return this.products.indexOf(productId) !== -1;
    // }

    /*
     * Check if ids in categories array from product is existed in categories array of this provider
     */
    checkCategory(categories) {
        let isExisted = false;
        for (let i = 0; i < categories.length; i++) {
            if (this.categories.indexOf(categories[i]) !== -1) {
                isExisted = true;
                break;
            }
        }
        return isExisted;
    }
}

export default Provider;
