/**
 * Created by Max Gornostayev on 10/28/21
 *
 * This is a library for working with FinAPI
 */

import getUid from 'get-uid';
import API from '../api';
import trans from '../trans';
import codes from '../const/codes';

class FinAPI {
    static instance;

    static getInstance() {
        if (!FinAPI.instance) {
            FinAPI.instance = new FinAPI();
        }
        return FinAPI.instance;
    }

    /*
     * generate finapi customer object
     */
    generateFinApiCustomer() {
        const uid = getUid();
        const obj = {
            id: 'username-' + uid,
            password: 'password-' + uid,
            email: 'email' + uid + '@email.com',
            phone: uid,
        };

        //for testing
        // const obj = {
        //     email: "email1006957865@email.com",
        //     id: "username-1006957865",
        //     password: "password-1006957865",
        //     phone: "1006957865",
        // }

        return obj;
    }

    /*
     * authorize app client
     */
    async authorizeClient() {
        const res = await API.finAPI.getFinAPIToken({});
        if (res.status) {
            API.finAPI.setAccessToken(res.data.access_token);
        }
        return res;
    }

    /*
     * authorize app client
     */
    async authorizeCustomer(userObj) {
        const res = await API.finAPI.getFinAPIToken({ username: userObj.id, password: userObj.password });
        if (res.status) {
            API.finAPI.setAccessToken(res.data.access_token);
        }
        return res;
    }

    /*
     * get web url
     */
    async getWebURL(userObj) {
        //authorize client
        let res = await this.authorizeClient();
        if (!res.status) {
            return res;
        }
        //create a customer
        res = await API.finAPI.createCustomer(userObj);
        if (!res.status) {
            return res;
        }
        //authorize customer
        res = await this.authorizeCustomer(userObj);
        if (!res.status) {
            return res;
        }
        //getting new webURL for form
        res = await API.finAPI.bankConnectionImport();
        return res;
    }

    /*
     * get status of webform
     */
    async getWebForm(formId) {
        //authorize client
        //get a webform
        const res = await API.finAPI.getWebForm(formId);
        if (!res.status) {
            return res;
        }

        return res;
    }

    /*
     * get data source id
     */
    async getDataSourceId(bankConnectionId) {
        //get account ids
        let res = await API.finAPI.getBankConnection(bankConnectionId);
        if (!res.status) {
            return res;
        }
        const { accountIds } = res.data;
        if (!Array.isArray(accountIds) || !accountIds.length) {
            return API.finAPI.getResponseObj(false, codes.statusInternalServerError, {}, trans.t('finApi', 'noAccountIds'));
        }

        //get dataSourceIds from synchronize bank connection data sources
        res = await API.finAPI.getSynchronizeBankConnection(accountIds);
        if (!res.status) {
            return res;
        }
        const { bankConnections } = res.data;
        if (!Array.isArray(bankConnections) || !bankConnections.length) {
            return API.finAPI.getResponseObj(false, codes.statusInternalServerError, {}, trans.t('finApi', 'noBankConnections'));
        }
        const dataSourceIds = bankConnections.map((bankConnection) => bankConnection.dataSourceId);

        const dataSourceId = dataSourceIds.length ? dataSourceIds[0] : '';

        return dataSourceId
            ? API.finAPI.getResponseObj(true, codes.statusOK, { dataSourceId })
            : API.finAPI.getResponseObj(false, codes.statusInternalServerError, {}, trans.t('finApi', 'noDataSourceIds'));
    }

    /*
     * get status of datasourceid
     */
    async getDataSourceIdStatus(dataSourceId) {
        const res = await API.finAPI.getDatasourceStatus(dataSourceId);
        return res;
    }

    /*
     * get contracts
     */
    async getContracts(dataSourceId) {
        //create case based on data source ids
        let res = await API.finAPI.createCase([dataSourceId], 730, false);
        if (!res.status) {
            return res;
        }
        const caseId = res.data.id;

        //create a report
        res = await API.finAPI.createInsuranceReport(caseId);
        if (!res.status || !res.data.id) {
            return res;
        }

        //get contracts inside of the report
        res = await API.finAPI.getReport(res.data.id);

        if (!res.status) {
            return res;
        }

        if (!res.data || !res.data.reports || !res.data.reports.contractsInsurance) {
            return API.finAPI.getResponseObj(false, codes.statusInternalServerError, {}, trans.t('finApi', 'noContracts'));
        }

        return res;
    }
}

export default FinAPI.getInstance();
