/**
 * Created by Max Gornostayev on 05/01/22
 *
 * this is a visualization steps for multi-steps forms
 */

import IconCheckWhite from '../../assets/icon-checkbox-white-big.png';

export default function SignupSteps({ currentStep }) {
    // helper for get active classes on circles
    const currentStepPrev = currentStep - 1;

    return (
        <div className="wrapper-steps">
            <div className={!currentStepPrev || currentStep > 1 ? 'form-steps-circle active' : 'form-steps-circle'}>
                {currentStep > 1 || currentStep === 2 ? <img src={IconCheckWhite} alt="" className="current-step-form" /> : 1}
            </div>
            <div className="form-steps-separator-line" />
            <div className={currentStepPrev === 1 || currentStep === 3 ? 'form-steps-circle active' : 'form-steps-circle'}>
                {currentStep > 2 || currentStep === 3 ? <img src={IconCheckWhite} alt="" className="current-step-form" /> : 2}
            </div>
            <div className="form-steps-separator-line" />
            <div className={currentStep === 3 ? 'form-steps-circle active' : 'form-steps-circle'}>3</div>
        </div>
    );
}
