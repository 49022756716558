/**
 * Created by Max Gornostayev on 05/26/22
 *
 * this is a button that is shown on header in dashboard or other secure pages
 */

import IconArrowRightSmall from '../../assets/icon-arrow-small.svg';

export default function ButtonDashboard({ onClick, className, icon, titleNumber, titleText }) {
    //on lick handler when click on the link
    const click = () => {
        if (onClick) {
            onClick();
        }
    };
    //prepare class name for container
    let clssName = 'button-dashboard';
    if (className) {
        clssName += ' ' + className;
    }
    return (
        <div className={clssName} onClick={click} style={!onClick ? { cursor: 'default' } : {}}>
            {!!icon && <img src={icon} alt="icon" />}
            <div className="text">
                {<div className="number">{titleNumber}</div>}
                {!!titleText && (
                    <div className="row-spacebetween-end">
                        <div className="title">{titleText}</div>
                        {!!onClick && <img src={IconArrowRightSmall} alt="arrow" />}
                    </div>
                )}
            </div>
        </div>
    );
}
