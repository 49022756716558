/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a yes/no element
 */

import React from 'react';
import trans from '../../trans';
import PropTypes from 'prop-types';

class YesNo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            isValid: true,
        };
    }

    //onchange handler
    onChangeHandler = () => {
        const { value } = this.state;
        const newValue = !value;
        this.setState({ value: newValue });

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    //validation
    validate = () => {
        const { value } = this.state;
        if (!value) {
            this.setState({ isValid: false });
        }
    };

    render() {
        const { value, isValid } = this.state;
        const { role } = this.props;
        const innerClass = 'inner ' + (!value ? 'inner-no' : 'inner-yes');
        let className = 'form-yesno';
        if (!isValid) {
            className += ' not-valid';
        }

        return (
            <div className={className} onClick={this.onChangeHandler} role={role}>
                <div className={innerClass}>
                    {!value && <div className="circle" />}
                    <div className="text">{trans.t('elements', !value ? 'no' : 'yes')}</div>
                    {!!value && <div className="circle" />}
                </div>
            </div>
        );
    }
}

YesNo.defaultProps = {
    defaultValue: '',
    role: 'inputYesNoId',
    onChange: () => {},
};
YesNo.propTypes = {
    defaultValue: PropTypes.any,
    role: PropTypes.string,
    onChange: PropTypes.func,
};

export default YesNo;
