/**
 * Created by Max Gornostayev on 07/09/22
 *
 * MODEL - document model
 */
import DateUtil from '../../lib/DateUtil';
import API from '../../api';

class Document {
    constructor(obj) {
        this.id = obj.documentId || 0;
        this.filename = obj.filename || 'noname';
        this.filetype = obj.filetype || '';
        this.createdAt = obj.createdAt || DateUtil.getFormatServerDate();
    }

    /*
     * get title
     */
    get title() {
        return this.filename || '';
    }

    /*
     * get title of the created date
     */
    get dateTitle() {
        return DateUtil.getFormatDate(this.createdAt);
    }

    /*
     * get all documents of the contract
     * @param contractId - int
     */
    static async getAllDocuments(contractId) {
        const res = await API.contract.getDocuments(contractId);
        return res;
    }

    /*
     * delete one document
     * @param contractId - int
     */
    static async delete(documentId) {
        const res = await API.contract.deleteDocument(documentId);
        return res;
    }

    /*
     * gete document from contract
     * @param contractId - int
     */
    static async getData(documentId) {
        const res = await API.contract.getDocument(documentId);
        return res;
    }
}

export default Document;
