/**
 * Created by Max Gornostayev on 2/28/22
 *
 * MODEL - product type
 */

import Main from './Parent';
import model from './fields/product';
import syncLists from '../../const/syncLists';

class Product extends Main {
    constructor({ obj, syncObj }) {
        if (syncObj) {
            obj = Main.transformSyncObj(syncObj, syncLists.product);
        }
        super(obj, model);

        if (this.categories && typeof this.categories === 'string') {
            this.categories = this.categories.split(', ');
        }
    }

    get titleValue() {
        return this.title;
    }

    get idValue() {
        return this.serviceplatformId || this.id || '';
    }

    get finapiIdValue() {
        return this.finapiId || '';
    }

    get serviceplatformIdValue() {
        return this.serviceplatformId || '';
    }

    get isBroker() {
        return !!this.isBrokerPossible;
    }

    get categoriesValue() {
        return Array.isArray(this.categories) ? this.categories : [];
    }

    /*
     * Check if categoryId is existed in categories array
     */
    checkCategory(categoryId) {
        return this.categories.indexOf(categoryId) !== -1;
    }
}

export default Product;
