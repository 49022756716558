/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a input date
 */

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import DateUtil from '../../lib/DateUtil';

registerLocale('de', de);

class InputDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            value: this.props.defaultValue,
            validateMsg: '',
            isValid: true,
        };
    }

    //on change handler for picker
    onChangePicker = (value) => {
        const date = new Date(value);
        this.setState({ isOpened: false });
        this.onChange(DateUtil.convertFromDate(date));
    };

    //on change handler for input
    onChangeInput = (event) => {
        const { value } = event.target;
        this.onChange(value);
    };

    //on change handler
    onChange = (value) => {
        const { isValid, validateMsg } = this.validate(value);

        this.setState({ value, isValid, validateMsg });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    onClick = () => {
        const { isOpened } = this.state;
        this.setState({ isOpened: !isOpened });
    };

    //do validation
    validate = (checkValue) => {
        const value = checkValue || this.state.value;

        let isValid = true;
        let validateMsg = '';
        if (this.props.validateFunc) {
            const validObj = this.props.validateFunc(value);
            if (!validObj.validated) {
                isValid = false;
                validateMsg = validObj.msg;
            }
        }

        return { isValid, validateMsg };
    };

    //set validation msg
    setValidation = (isValid, validateMsg) => {
        this.setState({ isValid, validateMsg });
    };

    //on focus handler
    onFocus = () => {
        this.setState({ isOpened: false });
    };

    render() {
        let className = 'input-date';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        const isNotValid = !!this.props.validateFunc && !this.state.isValid;

        let titleClass = 'title';
        if (isNotValid) {
            titleClass += ' red';
        }

        return (
            <div className={className}>
                <div className="inner" role={this.props.role}>
                    <div className="container-inner">
                        {!!this.props.title && <div className={titleClass}>{this.props.title}</div>}
                        <input type="text" placeholder={this.props.placeholder || ''} value={this.state.value} onChange={this.onChangeInput} onFocus={this.onFocus} />
                    </div>
                    <div className="btn-icon" onClick={this.onClick} />
                </div>
                {this.state.isOpened && (
                    <div role="picker" className="picker">
                        <DatePicker showYearDropdown onChange={this.onChangePicker} inline locale="de" />
                    </div>
                )}
                {!!this.props.desc && <div className="desc">{this.props.desc}</div>}
                {isNotValid && <div className="error">{this.state.validateMsg}</div>}
            </div>
        );
    }
}

InputDate.defaultProps = {
    title: '',
    desc: '',
    defaultValue: null,
    placeholder: '',
    className: null,
    role: 'textInputTestId',
    validateFunc: null,
    onChangeFunc: () => {},
};
InputDate.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    role: PropTypes.string,
    validateFunc: PropTypes.func,
    onChange: PropTypes.func,
};

export default InputDate;
