/**
 * Created by Max Gornostayev on 06/4/22
 *
 * pageId: secureContractDocuments
 * path: /contract/documents
 * props:
 * @contractId - int - contract id
 *
 * this is a page that show documents for one contract
 */

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import HeaderSecure from '../../../components/theme/HeaderSecure';
import Button from '../../../components/buttons/Button';
import ProviderImage from '../../../components/elements/ProviderImage';
import Loading from '../../../components/elements/Loading';
import Alert from '../../../components/popups/Alert';
import Select from '../../../components/forms/Select';
import Document from '../../../models/entity/Document';
import trans from '../../../trans';
import Utils from '../../../lib/Utils';
import DateUtil from '../../../lib/DateUtil';
import '../../../styles/secure.scss';
import IconPlusSmall from '../../../assets/icon-new-plus-small.png';

const ContractDocumentsPage = observer(({ ContractsStore }) => {
    //get state for getting props
    const { state } = useLocation();

    //get prop 'contractId' from another screen
    const contractId = state && state.hasOwnProperty('contractId') ? state.contractId : 0;

    //get contract
    const contract = ContractsStore.getContract(contractId);

    //state variables
    const [isLoading, setIsLoading] = useState(contract.numberDocumentsValue > 0);
    const [documents, setDocuments] = useState(contract.documentsList);
    const [filterYear, setFilterYear] = useState(2022);
    const listYears = [
        { value: 2022, label: 2022 },
        { value: 2023, label: 2023 },
    ];

    //load documents from server
    useEffect(async () => {
        if (contract.numberDocumentsValue > 0) {
            const docs = await contract.loadDocuments();
            setIsLoading(false);
            if (docs.length) {
                docs.filter((obj) => DateUtil.getYear(obj.createdAt) === filterYear);
                setDocuments(docs);
            }
        }
    }, []);

    //ref variable for popup
    const refAlert = useRef();

    // dropzones variables
    const { open } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        multiple: false,
        onDrop: async (files) => {
            if (files.length) {
                await uploadDocument(files[0]);
            }
        },
    });

    //navigate variable that is used to go to another screen
    // const navigate = useNavigate();

    //upload document
    const uploadDocument = async (file) => {
        setIsLoading(true);
        const res = await contract.saveNewDocument(file);
        if (!res.status) {
            setIsLoading(false);
            refAlert.current.error(res.msg);
            return false;
        }
        const docs = await contract.loadDocuments();
        setIsLoading(false);
        if (docs.length) {
            setDocuments(docs);
        }
        ContractsStore.updateDocumentNumber(contract.idValue, 1);
        refAlert.current.show(trans.t('secureContractDocumentsPage', 'documentAdded'));
        return true;
    };

    //delete document
    const deleteDocument = async (documentId) => {
        setIsLoading(true);
        const res = await contract.deleteDocument(documentId);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        refAlert.current.show(trans.t('secureContractDocumentsPage', 'documentDelete'));
        ContractsStore.updateDocumentNumber(contract.idValue, -1);
        return true;
    };

    //delete document
    const openDocument = async (documentId) => {
        setIsLoading(true);
        const res = await Document.getData(documentId);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        if (res.data) {
            const linkSource = res.data.file;
            const downloadLink = document.createElement('a');
            const fileName = res.data.filename;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }

        return true;
    };

    //get props of contracts
    const { productTitle, providerTitle, brokerStatusValue, numberValue, yearlyCostValue, statusTitle } = contract;

    //render document item
    const renderDocumentItem = (document, index) => {
        if (DateUtil.getYear(document.createdAt) !== filterYear) {
            return null;
        }
        return (
            <div className="item" key={index}>
                <div>
                    <div className="date">{document.dateTitle}</div>
                    <div role={'btnOpen' + document.id} className="title" onClick={() => openDocument(document.id)}>
                        {document.title}
                    </div>
                </div>
                <div className="buttons">
                    <div role={'btnDelete' + document.id} className="delete" onClick={() => deleteDocument(document.id)} />
                </div>
            </div>
        );
    };

    //render function
    return (
        <div className="app secure-contract-documents-page">
            <HeaderSecure isBack>
                <div className="info">
                    <div className="inner">
                        <div className="title">{providerTitle}</div>
                        <h1>{productTitle}</h1>
                        <div className="detail">
                            <div className="detail-item">
                                <ProviderImage title={providerTitle} isHeaderStyle />
                            </div>
                            <div className="detail-item">
                                <div className="title">{trans.t('secureContractDocumentsPage', 'contractNumber')}.:</div>
                                <div>{numberValue || '-'}</div>
                            </div>
                            <div className="detail-item">
                                <div className="title">{trans.t('secureContractDocumentsPage', 'contractYearlyCost')}:</div>
                                <div>{Utils.formatPrice(yearlyCostValue)}</div>
                            </div>
                            <div className="detail-item">
                                <div className="title">{trans.t('secureContractDocumentsPage', 'contractStatus')}:</div>
                                <div>{statusTitle || '-'}</div>
                            </div>
                            <div className="detail-item">
                                <div className="title">{trans.t('secureContractDocumentsPage', 'contractCashback')}:</div>
                                <div className="cashback">{trans.t('secureContractDocumentsPage', 'brokerStatus_' + brokerStatusValue)}</div>
                            </div>
                            <Button text={trans.t('secureContractDocumentsPage', 'btnAddDocument')} onClick={open} isTransparent isLeftAlign leftIcon={IconPlusSmall} />
                        </div>
                    </div>
                </div>
            </HeaderSecure>
            <div className="content">
                <div className="wrapper-big">
                    <div className="list-header">
                        <h4>{trans.t('secureContractDocumentsPage', 'h4')}</h4>
                        <div className="year">
                            <Select defaultValue={filterYear} options={listYears} onChange={(val) => setFilterYear(val)} noBorder />
                        </div>
                    </div>
                    <div className="list">{documents.map((document, index) => renderDocumentItem(document, index))}</div>
                </div>
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractDocumentsPage;
