/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingFinApiImport
 * path: /onboarding/vertrag/import-bank
 *
 * this is a page for getting contracts from FinAPI
 */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import ReactLoading from 'react-loading';
import { timer } from 'rxjs';
import { load } from '@finapi/web-form';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import FinAPICore from '../../core/FinAPI';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Loading from '../../components/elements/Loading';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import config from '../../config';
import trans from '../../trans';

const FinAPIImportPage = observer(({ ContractsStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    //get prop 'formId' from another screen
    const { state } = useLocation();
    const formId = state && state.hasOwnProperty('formId') ? state.formId : '';

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //ref variable for popup
    const refAlert = useRef();

    let subscription = null;

    useEffect(() => {
        function unsub() {
            if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        }

        return unsub;
    }, []);

    //handler for error that could happen during finapi form editing
    const proccessError = () => {
        setIsLoading(false);
        // refAlert.current.show(msg, 'error');
        navigate(pageUrl.onboardingFinApiError);
    };

    //handler for any case that could happene in form and try to restart it
    const proccessRestart = () => {
        setIsLoading(false);
        // refAlert.current.show(msg, 'error');
        navigate(pageUrl.onboardingFinApiRestart);
    };

    //proccess the datasource and get all contracts and setup it to store
    const proccessContracts = async (dataSourceId) => {
        const res = await FinAPICore.getContracts(dataSourceId);
        if (!res.status || !res.data.reports || !res.data.reports.contractsInsurance || !res.data.reports.contractsInsurance.contractsData.length) {
            proccessError(res.msg);
            return false;
        }
        ContractsStore.setFinAPIContracts(res.data);
        setIsLoading(false);
        navigate(pageUrl.onboardingContractList, { state: { isFinapi: true } });
        return true;
    };

    //FinAPI handlers that should be passed into Finapi component
    const finAPIHandlers = {
        onComplete: async () => {
            try {
                setIsComplete(true);
                setIsLoading(true);
                let res = await FinAPICore.getWebForm(formId);
                if (!res.status) {
                    proccessError(res.msg);
                    return false;
                }
                if (res.data.status !== 'COMPLETED' && res.data.payload && res.data.payload.bankConnectionId) {
                    proccessError('No bank connections');
                    return false;
                }
                res = await FinAPICore.getDataSourceId(res.data.payload.bankConnectionId);
                if (!res.status) {
                    proccessError(res.msg);
                    return false;
                }
                const { dataSourceId } = res.data;

                //we need to check each 3 seconds the status of datasource as the status is changed not at once after form is finished
                let timeToCancel = 0;
                const subcriptionTimout = () => {
                    if (timeToCancel > 60000) {
                        subscription.unsubscribe();
                        proccessRestart();
                    }
                    timeToCancel += 3000;
                };
                subscription = timer(1000, 3000).subscribe(async () => {
                    const res = await FinAPICore.getDataSourceIdStatus(dataSourceId);
                    if (res.status && res.data && res.data.status) {
                        switch (res.data.status) {
                            case 'SUCCESSFUL':
                                subscription.unsubscribe();
                                await proccessContracts(dataSourceId);
                                break;
                            case 'FAILED':
                                subscription.unsubscribe();
                                proccessError();
                                break;
                            default:
                                subcriptionTimout();
                                break;
                        }
                    } else {
                        subcriptionTimout();
                    }
                });
            } catch (e) {
                proccessError(e);
            }
        },
        onFail: () => {
            proccessRestart();
        },
        onAbort: () => {
            navigate(pageUrl.onboardingContractAdd);
        },
    };

    //create a finapi  webform function
    const createWebForm = useCallback((target) => {
        load(target, { token: formId, targetEnvironment: config.finAPI.env }, finAPIHandlers);
    }, []);

    //ref for finapi container
    const finAPIContainerRef = (container) => {
        if (container) {
            createWebForm(container);
        }
    };

    //main rendering
    return (
        <div className="app onboarding-finapi-import-page">
            <Header isBack paddingBottom={0} />
            <div className="content">
                <div className="wrapper-big">
                    {!isComplete && <div ref={finAPIContainerRef} />}
                    {isComplete && (
                        <div className="complete">
                            <h1>{trans.t('onboardingFinAPIImportPage', 'h1Complete')}</h1>
                            <div>{trans.t('onboardingFinAPIImportPage', 'textComplete')}</div>
                            <div className="loading-icon">
                                <ReactLoading type="spin" color="#234557" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <BottomLinks />
            <Alert ref={refAlert} />
            {!isComplete && <Loading isShow={isLoading} />}
        </div>
    );
});

export default FinAPIImportPage;
