export default {
    product: {
        storeName: 'products',
        iblockId: 109,
        id: 'techId',
        title: 'field',
        fields: ['techId', 'fields', 'name'],
        selectFields: {
            title: 'PROPERTY_1504',
            serviceplatformId: 'PROPERTY_1505',
            finapiId: 'PROPERTY_2341',
            categories: 'PROPERTY_1496',
            isBrokerPossible: 'PROPERTY_1501',
            status: 'PROPERTY_1493',
        },
        values: {
            isBrokerPossible: {
                yes: 727,
                no: 728,
            },
            status: {
                active: 714,
                inactive: 715,
                forImplementation: 716,
            },
        },
        isActive: {
            field: 'PROPERTY_1493',
            value: [714, 716], //Active values
        },
    },
    provider: {
        storeName: 'providers',
        iblockId: 106,
        id: 'techId',
        title: 'name',
        logo: 'logo',
        fields: ['techId', 'fields', 'name', 'logo'],
        selectFields: {
            // title: 'PROPERTY_2406',
            serviceplatformId: 'PROPERTY_1437',
            finapiId: 'PROPERTY_2405',
            isBrokerPossible: 'PROPERTY_1435',
            status: 'PROPERTY_1431',
            categories: 'PROPERTY_1433',
            // products: 'PROPERTY_1434',
        },
        values: {
            isBrokerPossible: {
                yes: 690,
                no: 691,
                open: 692,
            },
            status: {
                new: 685,
                update: 686,
                active: 687,
                check: 688,
                lost: 689,
            },
        },
        isActive: {
            field: 'PROPERTY_1431',
            value: [687, 685, 686], //Active values
        },
    },
    brokerStatus: {
        storeName: 'brokerStatuses',
        iblockId: 107,
        id: 'techId',
        title: 'name',
        fields: ['techId', 'fields', 'name'],
        selectFields: {
            title: 'PROPERTY_1483',
            serviceplatformId: 'PROPERTY_1484',
            isBrokerPossible: 'PROPERTY_1482',
            status: 'PROPERTY_1476',
        },
        values: {
            isBrokerPossible: {
                yes: 712,
                no: 713,
            },
            status: {
                active: 710,
                inactive: 711,
            },
        },
        isActive: {
            field: 'PROPERTY_1476',
            value: [710], //Active values
        },
    },
    contractStatus: {
        storeName: 'contractStatuses',
        iblockId: 131,
        title: 'name',
        fields: ['techId', 'fields', 'name'],
        selectFields: {
            title: 'PROPERTY_1849',
            serviceplatformId: 'PROPERTY_1851',
            finapiId: 'PROPERTY_2448',
            isBrokerPossible: 'PROPERTY_1847',
            status: 'PROPERTY_1841',
        },
        values: {
            isBrokerPossible: {
                yes: 897,
                no: 898,
            },
            status: {
                active: 893,
                inactive: 894,
            },
        },
        isActive: {
            field: 'PROPERTY_1841',
            value: [893], //Active values
        },
    },
    paymentMethod: {
        storeName: 'paymentMethods',
        iblockId: 132,
        id: 'techId',
        title: 'name',
        fields: ['techId', 'fields', 'name'],
        selectFields: {
            title: 'PROPERTY_1850',
            serviceplatformId: 'PROPERTY_1852',
            finapiId: 'PROPERTY_2449',
            isBrokerPossible: 'PROPERTY_1848',
            status: 'PROPERTY_1842',
        },
        values: {
            isBrokerPossible: {
                yes: 899,
                no: 900,
            },
            status: {
                active: 895,
                inactive: 896,
            },
        },
        isActive: {
            field: 'PROPERTY_1842',
            value: [895], //Active values
        },
    },
    salutation: {
        storeName: 'salutations',
        iblockId: 125,
        id: 'techId',
        title: 'name',
        fields: ['techId', 'fields', 'name'],
        selectFields: {
            title: 'PROPERTY_1720',
            serviceplatformId: 'PROPERTY_1722',
            status: 'PROPERTY_1717',
        },
        values: {
            status: {
                active: 821,
                inactive: 822,
            },
        },
        isActive: {
            field: 'PROPERTY_1717',
            value: [821], //Active values
        },
    },
};
