/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API requests for customer
 */

import JwtAPI from '../JwtAPI';
import AuthAPI from '../AuthAPI';

const API = {
    /*
     * create customer
     * @userObject - user object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-4c4b4428-586f-4365-9700-66e639e4d5d2
     */
    create: async (userObject, isCompany) => {
        if (isCompany) {
            JwtAPI.setHeaderCommon(process.env.REACT_APP_CUSTOMER_COMPANY_KEY, process.env.REACT_APP_CUSTOMER_COMPANY_VALUE);
        } else {
            JwtAPI.setHeaderCommon(process.env.REACT_APP_CUSTOMER_PRIVATE_KEY, process.env.REACT_APP_CUSTOMER_PRIVATE_VALUE);
        }
        const res = await JwtAPI.request('post', '1', '/customer', userObject);
        JwtAPI.setHeaderCommon(process.env.REACT_APP_CUSTOMER_COMPANY_KEY, '');
        JwtAPI.setHeaderCommon(process.env.REACT_APP_CUSTOMER_PRIVATE_KEY, '');
        return res;
    },

    /*
     * update customer
     * @userObject - user object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-4c4b4428-586f-4365-9700-66e639e4d5d2
     */
    update: async (userObject) => {
        const res = await AuthAPI.request('patch', '1', '/customer', userObject);
        return res;
    },

    /*
     * get customer
     * @userObject - user object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-4c4b4428-586f-4365-9700-66e639e4d5d2
     */
    get: async () => {
        const res = await AuthAPI.request('get', '1', '/customer');
        return res;
    },

    /*
     * create customer
     * @userObject - object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-ffdbbfee-c9fa-4ee5-933c-9acae9e39ce7
     */
    signBroker: async (obj) => {
        const res = await AuthAPI.request('post', '1', '/broker/agreement', obj);
        return res;
    },

    /*
     * get broker agreement files
     * @example - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-27f460bc-f03a-4a22-9f8b-e9cf784ffb5f
     */
    getBrokerAgreement: async () => {
        const res = await AuthAPI.request('get', '1', '/broker/agreement');
        return res;
    },
};

export default API;
