/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that show provider image
 */

import Utils from '../../lib/Utils';
import trans from '../../trans';

export default function ProviderImage({ title, imageSrc, isHeaderStyle }) {
    // imageSrc = 'https://bancassurance.go-digital-software.de/bitrix/tools/disk/uf.php?attachedId=17&auth%5Baplogin%5D=1&auth%5Bap%5D=8jhhtbi80z46swg5&action=show&ncc=1';
    // imageSrc = 'https://bancassurance.go-digital-software.de/bitrix/tools/disk/uf.php?attachedId=16&auth%5Baplogin%5D=1&auth%5Bap%5D=8jhhtbi80z46swg5&action=show&ncc=1';
    let className = 'provider-image';
    if (isHeaderStyle) {
        className += ' provider-header';
    }
    return (
        <div className={className}>
            {!!imageSrc && <img src={imageSrc} alt={title} />}
            {!imageSrc && <div className="title">{title ? Utils.addDotsToString(title, 13) : trans.t('elements', 'textAddProvider')}</div>}
        </div>
    );
}
