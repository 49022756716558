const objModel = {
    id: '',
    title: '',
    logo: null,
    serviceplatformId: '',
    finapiId: '',
    isBrokerPossible: false,
    status: null,
    categories: [],
    // products: [],
};

export default objModel;
