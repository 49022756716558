/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingFinApiRestart
 * path: /onboarding/vertrag/import-bank-restart
 *
 * this is a restart page that could be shown after finapi triggers an expired or some other unexpected thing in finapi logic
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/onboarding.scss';

const FinAPIErrorPage = observer(() => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const next = () => {
        navigate(pageUrl.onboardingContractAdd);
    };

    //main rendering
    return (
        <div className="app onboarding-finapi-restart-page">
            <Header expertTitle={trans.t('onboardingFinAPIRestartPage', 'expertTitle')} expertMsg={trans.t('onboardingFinAPIRestartPage', 'expertMsg')} expertClass="expert-info">
                <div className="wrapper-vsmall">
                    <Button role="btnNext" text={trans.t('onboardingFinAPIRestartPage', 'btnTitle')} onClick={next} isRightIcon isWhite isFullWidth className="margin-top-32" />
                </div>
            </Header>
            <BottomLinks isBgBlue />
        </div>
    );
});

export default FinAPIErrorPage;
