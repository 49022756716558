/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is API requests lists from broker crm server
 */

import JwtAPI from '../JwtAPI';

const API = {
    /*
     * get elements
     * @iblockId - int, server id
     * @fields - array, list of properties that will be inside response
     * @limit - count of items
     * @offset - start position
     */
    getElements: async (iblockId, fields, selectFields, activeObj, limit, offset) => {
        const params = {
            iblockId,
            select: fields,
            select_fields: selectFields,
            limit: limit || 500,
            offset: offset || 0,
        };
        if (activeObj && activeObj.field && activeObj.value) {
            const field = 'field_value[' + activeObj.field + ']';
            params[field] = activeObj.value;
        }
        const res = await JwtAPI.request('get', '1', '/list-element-full', null, params);
        return res;
    },
};

export default API;
