/**
 * Created by Max Gornostayev on 06/4/22
 *
 * pageId: secureCustomerProfile
 * path: /profile
 *
 * this is a page that show profile of the customer
 */

import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import HeaderSecure from '../../../components/theme/HeaderSecure';
import ButtonLink from '../../../components/buttons/ButtonLink';
import Alert from '../../../components/popups/Alert';
import Loading from '../../../components/elements/Loading';
import Customer from '../../../models/entity/Customer';
import pageUrl from '../../../const/pages';
import trans from '../../../trans';
import config from '../../../config';
import '../../../styles/secure.scss';

const CustomerProfilePage = observer(({ UserStore, DataStore }) => {
    //get customer
    const { customer, isCompany } = UserStore;

    const [isLoading, setIsLoading] = useState(false);

    //ref variable for popup
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //delete contract function
    const sendConfirmationEmail = async () => {
        setIsLoading(true);
        navigate(pageUrl.secureContractsList);
        return true;
    };

    //get pdf files function
    const getPdfFiles = async () => {
        setIsLoading(true);
        const res = await Customer.getBrokerAgreementFiles();
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
        }
        return res;
    };

    //show broker pdf document
    const showBrokerPdf = async () => {
        const res = await getPdfFiles();
        if (res.data && res.data.brokerAgreement) {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.data.brokerAgreement.file;
            downloadLink.download = 'broker_agreement.pdf';
            downloadLink.click();
        }

        return true;
    };

    //show privacy pdf document
    const showPrivacyPdf = async () => {
        const res = await getPdfFiles();
        if (res.data && res.data.privacyTerms) {
            const downloadLink = document.createElement('a');
            downloadLink.href = res.data.privacyTerms.file;
            downloadLink.download = 'privacy_agreement.pdf';
            downloadLink.click();
        }

        return true;
    };

    //render function
    return (
        <div className="app secure-customer-profile-page">
            <HeaderSecure isBack title={trans.t('secureCustomerProfilePage', isCompany ? 'h1Company' : 'h1')} />
            <div className="content">
                <div className="wrapper-big">
                    <div className="detail">
                        {isCompany && (
                            <div className="item">
                                <div className="title">{trans.t('secureCustomerProfilePage', 'companyName')}:</div>
                                <div>{customer.companyNameValue}</div>
                            </div>
                        )}
                        {isCompany && <h4>{trans.t('secureCustomerProfilePage', 'contactPerson')}</h4>}
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'salutation')}:</div>
                            <div>{customer.salutationTitle}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'firstName')}:</div>
                            <div>{customer.firstNameValue}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'lastName')}:</div>
                            <div>{customer.lastNameValue}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'birthdate')}:</div>
                            <div>{customer.birthdateValue}</div>
                        </div>
                        <h4>{trans.t('secureCustomerProfilePage', 'titleCommunication')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'phoneCode')}:</div>
                            <div>{customer.phoneCodeTitle}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'phoneNumber')}:</div>
                            <div>{customer.phoneNumberValue}</div>
                        </div>
                        <div className="item item-email">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'email')}:</div>
                            <div>{customer.emailValue}</div>
                            {!customer.isEmailVerified && (
                                <div className="email-not-confirmed">
                                    {trans.t('secureCustomerProfilePage', 'emailNotConfirmed')} -{' '}
                                    <span role="btnSendConfirm" onClick={sendConfirmationEmail}>
                                        {trans.t('secureCustomerProfilePage', 'emailNotConfirmedLink')}
                                    </span>
                                </div>
                            )}
                        </div>
                        <h4>{trans.t('secureCustomerProfilePage', 'titleAddress')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'street')}:</div>
                            <div>{customer.addressValue}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'postcode')}:</div>
                            <div>{customer.postcodeValue}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'city')}:</div>
                            <div>{customer.cityValue}</div>
                        </div>
                        <h4>{trans.t('secureCustomerProfilePage', 'titleBroker')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureCustomerProfilePage', 'dateActiveBroker')}:</div>
                            <div>{customer.activatedBrokerDate}</div>
                        </div>
                        <div className="item-buttons">
                            <ButtonLink onClick={showBrokerPdf} text={trans.t('secureCustomerProfilePage', 'btnShowBrokerPdf')} role="btnShowBroker" />
                            <ButtonLink onClick={showPrivacyPdf} text={trans.t('secureCustomerProfilePage', 'btnShowPrivacyPdf')} role="btnShowPrivacy" />
                            <ButtonLink
                                onClick={() => window.open(config.bitrix24Urls.cancelBroker, '_blank').focus()}
                                text={trans.t('secureCustomerProfilePage', 'btnCancelBroker')}
                                role="btnCancelBroker"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default CustomerProfilePage;
