/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is contracts store to store all customer's contracts
 */

import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Contract from '../models/entity/Contract';
import config from '../config';

class ContractsStore {
    /*
     * this is contract to add in onboarding
     */
    contractTemp = new Contract();

    /*
     * contracts
     */
    contracts = new Map();

    /*
     * contract ids for broker
     */
    checkedIds = [];

    /*
     * is persist store loaded
     */
    isLoaded = false;

    /*
     * is contract loaded
     */
    isLoadedContracts = false;

    /*
     * This is constructor
     */
    constructor() {
        makeAutoObservable(this);

        makePersistable(this, {
            name: 'BancassuranceContractsStore',
            properties: ['checkedIds'],
            storage: window.localStorage,
            removeOnExpiration: true,
            expireIn: config.storesExpiretion.contracts,
            debugMode: false,
        }).then(
            action(() => {
                this.setIsLoaded(true);
            })
        );
    }

    /*
     * get number of contracts
     */
    get getNumberOfContracts() {
        return this.contracts.size || 0;
    }

    /*
     * get all payments value
     */
    get getPayments() {
        return 0;
    }

    /*
     * load store
     */
    async loadStore() {
        const res = await Contract.getContracts();
        if (res.status && Array.isArray(res.data)) {
            this.contracts = new Map();
            res.data.map((obj) => {
                const contract = new Contract(obj);
                this.contracts.set(contract.idValue, contract);
            });
        }
        this.setLoadedContracts(true);
        return res;
    }

    /*
     * set loaded contracts
     */
    setLoadedContracts(value) {
        this.isLoadedContracts = value;
    }

    /*
     * set checkedIds
     */
    setCheckedIds(checkedIds) {
        this.checkedIds = checkedIds;
    }

    /*
     * set contract
     */
    setContract(contractId, contract) {
        this.contracts.set(contractId, contract);
    }

    /*
     * get filtered contracts for contract list page
     */
    getFilteredContracts(searchText, statusContract, order) {
        const ret = [];
        searchText = searchText || '';
        statusContract = statusContract || 'active';
        order = order || 'asc';
        for (const [, contract] of this.contracts) {
            const textToSearch = contract.productTitle ? contract.productTitle.toLowerCase() : '';
            if ((!searchText || (searchText && textToSearch.indexOf(searchText.toLowerCase()) !== -1)) && contract.statusValue === statusContract) {
                ret.push(contract);
            }
        }
        ret.sort((objA, objB) => {
            const titleA = order === 'asc' ? objA.productTitle : objB.productTitle;
            const titleB = order === 'asc' ? objB.productTitle : objA.productTitle;
            return !!titleA && titleA.localeCompare(titleB, 'de');
        });

        return ret;
    }

    /*
     * set loaded store or no
     */
    setIsLoaded(value) {
        runInAction(() => {
            this.isLoaded = value;
        });
    }

    /*
     * clear store contracts store
     */
    clearStore() {
        this.contracts = new Map();
        this.contractTemp = new Contract();
        runInAction(() => {
            this.setCheckedIds([]);
        });
        this.setLoadedContracts(false);
    }

    /*
     * get onboarding contracts array with manual contracts or finapi contracts
     */
    getOnboardingContracts(isManual) {
        const contracts = [];
        for (const [, contract] of this.contracts) {
            if (contract.isTemp) {
                if (contract.isManual && isManual) {
                    contracts.push(contract);
                } else if (!contract.isManual && !isManual) {
                    contracts.push(contract);
                }
            }
        }
        return contracts;
    }

    /*
     * get contract
     */
    getContract(contractId) {
        if (this.contracts.has(contractId)) {
            return this.contracts.get(contractId);
        }
        return new Contract();
    }

    /*
     * start process to add a contract
     */
    startAddingContract() {
        this.contractTemp = Contract.getTempContract();
    }

    /*
     * start process to edit a contract
     */
    editContract(contractId) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            this.contractTemp = Contract.copyContract(contract);
            this.contractTemp.setProvider({ provider: '', customProvider: contract.customProviderValue });
            return true;
        }
        return false;
    }

    /*
     * delete contract from the list
     */
    deleteContract(contractId) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            if (!contract.isManual) {
                this.contracts.delete(contractId);
            } else {
                contract.setIsDeleted(true);
                this.contracts.set(contractId, contract);
            }

            return true;
        }
        return false;
    }

    /*
     * delete contract from the database
     */
    async removeContract(contractId) {
        if (this.contracts.has(contractId)) {
            const res = await Contract.remove(contractId);
            if (res.status) {
                this.contracts.delete(contractId);
            }
            return res;
        }
        return { status: false, msg: 'No Contract' };
    }

    /*
     * update contract
     */
    async updateContract(contractId, fields) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            const res = await contract.update(fields);
            if (res.status) {
                this.setContract(contractId, contract);
            }
            return res;
        }
        return { status: false, msg: 'No Contract' };
    }

    /*
     * undelete contract in the list
     */
    undeleteContract(contractId) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            contract.setIsDeleted(false);
            this.contracts.set(contractId, contract);
            return true;
        }
        return false;
    }

    /*
     * add temp contract into contracts
     */
    addTempContract() {
        if (!this.contractTemp.idValue) {
            return null;
        }
        const { contractTemp } = this;
        this.contracts.set(contractTemp.idValue, contractTemp);
        this.contractTemp = new Contract();
        return true;
    }

    /*
     * set product type of temp contract
     */
    setProductTypeForAddingContract(val) {
        const { contractTemp } = this;
        contractTemp.setProductType(val);
        this.contractTemp = contractTemp;
    }

    /*
     * set provider of temp contract
     */
    setProviderForAddingContract({ provider, customProvider, isBroker }) {
        const { contractTemp } = this;
        contractTemp.setProvider({ provider, customProvider });
        contractTemp.setIsBroker(isBroker);
        this.contractTemp = contractTemp;
    }

    /*
     * set field of the contract
     */
    setFieldForAddingContract({ number }) {
        const { contractTemp } = this;
        if (number) {
            contractTemp.setNumber(number);
        }
        this.contractTemp = contractTemp;
    }

    /*
     * set field of the contract
     */
    addDocumentsForAddingContract(documents) {
        const { contractTemp } = this;
        contractTemp.addNewDocuments(documents);
        this.contractTemp = contractTemp;
    }

    /*
     * set temp contract for activating as a broker
     */
    addTempContractForBroker(contractIds) {
        for (const [, contract] of this.contracts) {
            if (contractIds.indexOf(contract.idValue) !== -1) {
                contract.setChecked(true);
                this.contracts.set(contract.idValue, contract);
            }
        }
    }

    /*
     * set finApi contracts
     */
    setFinAPIContracts(data) {
        if (data.reports.contractsInsurance.contractsData) {
            const accountData = data.hasOwnProperty('accountData') ? data.accountData : [];
            data.reports.contractsInsurance.contractsData.map((obj) => {
                const contract = Contract.getTempContract();
                contract.setFinAPIData(obj, accountData);
                this.contracts.set(contract.idValue, contract);
            });
        }
    }

    /*
     * save temp contracts with documents into server and set chackedIds for broker activation
     */
    async saveTempContracts() {
        const arr = [];
        for (const [, contract] of this.contracts) {
            if (contract.isTemp) {
                const serverObj = contract.getServerObject();
                delete serverObj.contract.contractId;
                delete serverObj.brokerTransmission;
                arr.push(serverObj);
            }
        }
        let res = { status: true };
        if (arr.length) {
            const tempIds = [];
            res = await Contract.saveMultiContracts(arr);
            if (res.status && Array.isArray(res.data)) {
                for (const contractData of res.data) {
                    if (contractData.contractId && contractData.contractTempID && this.contracts.has(contractData.contractTempID)) {
                        const contract = this.contracts.get(contractData.contractTempID);
                        contract.setId(contractData.contractId);

                        this.contracts.set(contractData.contractId, contract);
                        this.contracts.delete(contractData.contractTempID);

                        await contract.saveNewDocuments();

                        if (contract.isChecked) {
                            tempIds.push(contractData.contractId);
                        }
                    }
                }
            }
            this.setCheckedIds([...this.checkedIds, ...tempIds]);
        }
        return res;
    }

    /*
     * update number of documents for contract
     * @param contractId - int
     * @param diff - int - difference how much we need to add to documents' number
     */
    updateDocumentNumber(contractId, diff) {
        if (this.contracts.has(contractId)) {
            const contract = this.contracts.get(contractId);
            contract.changeDocumentsNumber(diff);
            this.contracts.set(contractId, contract);
            return true;
        }
        return false;
    }

    /*
     * activate broker for checkedIds array
     */
    async activateBrokerForCheckedContracts() {
        if (this.checkedIds.length) {
            const res = await this.activateBrokerForContracts(this.checkedIds);
            return res;
        }
        return { status: false, msg: 'There is no checked contracts' };
    }

    /*
     * activate broker for contractIds array
     */
    async activateBrokerForContracts(contractIds) {
        const res = await Contract.activateBrokerForContracts(contractIds);
        if (res.status && res.data && Array.isArray(res.data)) {
            res.data.map((contract) => {
                if (!!contract && this.contracts.has(contract.contractId)) {
                    const contractObj = this.contracts.get(contract.contractId);
                    contractObj.setFields({ brokerStatus: 'on_work' });
                    this.setContract(contract.contractId, contractObj);
                }
            });
        }
        return res;
    }
}

const contractsrStore = new ContractsStore();
export default contractsrStore;
