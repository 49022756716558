/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is main component for application
 */

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { reaction } from 'mobx';
import { Provider, observer } from 'mobx-react';
import API from './api';
import config from './config';
import UserStore from './stores/UserStore';
import DataStore from './stores/DataStore';
import ContractsStore from './stores/ContractsStore';
import ScrollToTop from './components/elements/ScrollToTop';
import Loading from './components/elements/Loading';
import RouteAuth from './core/RouteAuth';
import RouteSecure from './core/RouteSecure';
import RouteOnboarding from './core/RouteOnboarding';
import pageUrl from './const/pages';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import TermsPage from './pages/onboarding/TermsPage';
import OnboardingContractAddPage from './pages/onboarding/ContractAddPage';
import OnboardingContractList from './pages/onboarding/ContractListPage';
import OnboardingProductsPage from './pages/onboarding/ProductsPage';
import OnboardingProvidersPage from './pages/onboarding/ProvidersPage';
import OnboardingContractEditPage from './pages/onboarding/ContractEditPage';
import ContractListFinishPage from './pages/onboarding/ContractListFinishPage';
import UserDataPage from './pages/onboarding/UserDataPage';
import OnboardingSignBrokerPage from './pages/onboarding/SignBrokerPage';
import OnboardingSignPrivacyPage from './pages/onboarding/SignPrivacyPage';
import OnboardingSignPowerAttorneyPage from './pages/onboarding/SignPowerAttorneyPage';
import OnboardingBrokerSuccessPage from './pages/onboarding/BrokerSuccessPage';
import OnboardingFinAPIImportPage from './pages/onboarding/FinAPIImportPage';
import OnboardingFinAPIErrorPage from './pages/onboarding/FinAPIErrorPage';
import OnboardingFinAPIRestartPage from './pages/onboarding/FinAPIRestartPage';
import AuthSignupStepOnePage from './pages/auth/SignupStepOnePage';
import AuthSignupStepTwoPage from './pages/auth/SignupStepTwoPage';
import AuthSignupStepThreePage from './pages/auth/SignupStepThreePage';
import AuthSignupStepFourPage from './pages/auth/SignupStepFourPage';
import AuthSigninPage from './pages/auth/SigninPage';
import AuthForgotEmailSendPage from './pages/auth/ForgotEmailSendPage';
import AuthForgotEmailConfirmPage from './pages/auth/ForgotEmailConfirmPage';
import AuthStartPrivatePage from './pages/auth/StartPrivatePage';
import AuthStartCompanyPage from './pages/auth/StartCompanyPage';
import StartRegisterPage from './pages/auth/StartRegisterPage';
import StartLoginPage from './pages/auth/StartLoginPage';
import ConfirmEmailPage from './pages/auth/ConfirmEmailPage';
import SecureDashboardPage from './pages/secure/DashboardPage';
import SecureContractsListPage from './pages/secure/contract/ContractsListPage';
import SecureContractDetailPage from './pages/secure/contract/ContractDetailPage';
import SecureContractDocumentsPage from './pages/secure/contract/ContractDocumentsPage';
import SecureCustomerProfilePage from './pages/secure/customer/CustomerProfilePage';
import SecureReferralPage from './pages/secure/customer/ReferralPage';
import './styles/app.scss';

const stores = { UserStore, DataStore, ContractsStore };

const App = observer(() => {
    //state variables
    const [isLoading, setIsLoading] = useState(true);

    //set debug mode if it's needed
    API.setDebug(config.isDebug);

    const finishLoadingData = ({ reactionUser, reactionData, reactionContracts }) => {
        if (UserStore.isLoaded && reactionUser) {
            reactionUser.dispose();
        }
        if (DataStore.isLoaded && reactionData) {
            reactionData.dispose();
        }
        if (ContractsStore.isLoaded && reactionContracts) {
            reactionContracts.dispose();
        }
        if (UserStore.isLoaded && DataStore.isLoaded && ContractsStore.isLoaded) {
            setIsLoading(false);
        }
    };

    reaction(
        () => UserStore.isLoaded,
        (v1, v2, reactionUser) => finishLoadingData({ reactionUser })
    );
    reaction(
        () => DataStore.isLoaded,
        (v1, v2, reactionData) => finishLoadingData({ reactionData })
    );
    reaction(
        () => ContractsStore.isLoaded,
        (v1, v2, reactionContracts) => finishLoadingData({ reactionContracts })
    );

    if (isLoading) {
        return <Loading isShow />;
    }

    return (
        <Provider>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path={pageUrl.root} element={<HomePage {...stores} />} />
                    <Route path={pageUrl.page404} element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate replace to={pageUrl.authStartRegister} />} />

                    <Route path={pageUrl.authStartRegister} element={<StartRegisterPage {...stores} />} />
                    <Route path={pageUrl.authStartLogin} element={<StartLoginPage {...stores} />} />
                    <Route path={pageUrl.authStartPrivate} element={<AuthStartPrivatePage {...stores} />} />
                    <Route path={pageUrl.authStartCompany} element={<AuthStartCompanyPage {...stores} />} />

                    <Route path={pageUrl.authForgotEmailSend} element={<AuthForgotEmailSendPage {...stores} />} />
                    <Route path={pageUrl.authForgotEmailConfirm} element={<AuthForgotEmailConfirmPage {...stores} />} />

                    <Route path={pageUrl.onboardingTerms} element={<TermsPage {...stores} />} />
                    <Route path={pageUrl.authConfirmEmail} element={<ConfirmEmailPage {...stores} />} />

                    <Route element={<RouteAuth {...stores} />}>
                        <Route path={pageUrl.authSignupStepOne} element={<AuthSignupStepOnePage {...stores} />} />
                        <Route path={pageUrl.authSignupStepTwo} element={<AuthSignupStepTwoPage {...stores} />} />
                        <Route path={pageUrl.authSignupStepThree} element={<AuthSignupStepThreePage {...stores} />} />
                        <Route path={pageUrl.authSignupStepFour} element={<AuthSignupStepFourPage {...stores} />} />
                        <Route path={pageUrl.authSigninPage} element={<AuthSigninPage {...stores} />} />
                    </Route>

                    <Route path={pageUrl.routeOnboarding} element={<RouteOnboarding {...stores} />}>
                        <Route path={pageUrl.onboardingContractAdd} element={<OnboardingContractAddPage {...stores} />} />
                        <Route path={pageUrl.onboardingContractList} element={<OnboardingContractList {...stores} />} />
                        <Route path={pageUrl.onboardingProducts} element={<OnboardingProductsPage {...stores} />} />
                        <Route path={pageUrl.onboardingProviders} element={<OnboardingProvidersPage {...stores} />} />
                        <Route path={pageUrl.onboardingContractEdit} element={<OnboardingContractEditPage {...stores} />} />
                        <Route path={pageUrl.onboardingContractListFinish} element={<ContractListFinishPage {...stores} />} />
                        <Route path={pageUrl.onboardingUserData} element={<UserDataPage {...stores} />} />
                        <Route path={pageUrl.onboardingSignBroker} element={<OnboardingSignBrokerPage {...stores} />} />
                        <Route path={pageUrl.onboardingSignPrivacy} element={<OnboardingSignPrivacyPage {...stores} />} />
                        <Route path={pageUrl.onboardingSignPowerAttorney} element={<OnboardingSignPowerAttorneyPage {...stores} />} />
                        <Route path={pageUrl.onboardingBrokerSuccess} element={<OnboardingBrokerSuccessPage {...stores} />} />
                        <Route path={pageUrl.onboardingFinApiImport} element={<OnboardingFinAPIImportPage {...stores} />} />
                        <Route path={pageUrl.onboardingFinApiError} element={<OnboardingFinAPIErrorPage {...stores} />} />
                        <Route path={pageUrl.onboardingFinApiRestart} element={<OnboardingFinAPIRestartPage {...stores} />} />
                    </Route>

                    <Route element={<RouteSecure {...stores} />}>
                        <Route path={pageUrl.secureDashboard} element={<SecureDashboardPage {...stores} />} />
                        <Route path={pageUrl.secureContractsList} element={<SecureContractsListPage {...stores} />} />
                        <Route path={pageUrl.secureContractDetail} element={<SecureContractDetailPage {...stores} />} />
                        <Route path={pageUrl.secureContractDocuments} element={<SecureContractDocumentsPage {...stores} />} />
                        <Route path={pageUrl.secureCustomerProfile} element={<SecureCustomerProfilePage {...stores} />} />
                        <Route path={pageUrl.secureReferral} element={<SecureReferralPage {...stores} />} />
                    </Route>
                </Routes>
            </Router>
        </Provider>
    );
});

export default App;
