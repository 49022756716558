/**
 * Created by Max Gornostayev on 10/1/21
 *
 * This is a api for user's requests
 */

import JwtAPI from '../JwtAPI';
import AuthAPI from '../AuthAPI';

const API = {
    /*
     * set Tokens for authorization
     */
    setTokens: (authToken, refreshToken) => AuthAPI.updateTokens(authToken, refreshToken),

    /*
     * send otp
     * postman documentation - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-95c99d55-bd28-4125-b631-11fa5d74a9cb
     * @param obj: {
     *   "email": "grashin@apik-it.com",
     *   "phoneCode": "0049",
     *   "phoneNumber": "13767763675"
     *  }
     */
    sendOTP: async (obj) => {
        const res = await AuthAPI.request('post', '1', '/register/otp/send', obj);
        return res;
    },

    /*
     * send otp
     * postman - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-02b12a1c-4f62-44f3-b35e-ca0f74336d52
     * @param obj: {
     * "code": "32224",
     * "phoneCode": "0049",
     * "phoneNumber": "13767763675"
     * }
     */
    sendOTPConfirm: async (obj) => {
        const res = await AuthAPI.request('post', '1', '/register/otp/confirmation', obj);
        return res;
    },

    /*
     * send email confirmation
     * postman - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-9cf027f6-729f-4e92-a65f-5ba09aacb686
     */
    sendEmailConfirmation: async (token) => {
        const res = await JwtAPI.request('post', '1', '/register/email/send', { token });
        return res;
    },

    /*
     * send otp
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-a0a9ffb8-3a09-48ba-8f95-8842b89ea34b
     */
    sendEmailConfirm: async (token) => {
        const res = await JwtAPI.request('post', '1', '/register/email/confirmation', { token });
        return res;
    },

    /*
     * update password
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-e0b1203a-d463-4cd9-9dce-eb8d0277d964
     */
    updatePassword: async (password, passwordToken) => {
        const res = await JwtAPI.request('post', '1', '/register/password', { password, passwordToken });
        return res;
    },

    /*
     * login customer with phone and password
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-00cedec2-220b-49cf-b49b-89560fd37108
     */
    loginWithPhoneAndPass: async (phoneCode, phoneNumber, password) => {
        const res = await JwtAPI.request('post', '1', '/login/pass', { phoneCode, phoneNumber, password });
        return res;
    },

    /*
     * login customer with email and password
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/example/18672024-00cedec2-220b-49cf-b49b-89560fd37108
     */
    loginWithEmailAndPass: async (email, password) => {
        const res = await JwtAPI.request('post', '1', '/login/pass', { email, password });
        return res;
    },

    /*
     * send email with confirm code to reset the password
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-d508c442-3d3f-444a-aa25-0ca270bc90c0
     */
    sendForgotEmail: async (email) => {
        const res = await JwtAPI.request('post', '1', '/forgot/email/send', { email });
        return res;
    },

    /*
     * confirm password when resetting the password
     * @object - https://go-digital-software.postman.co/workspace/Team-Workspace~c9b5c7f5-0818-41cf-8221-8ca02d3f072a/request/18672024-8f3127ae-8068-471d-b0e2-127a561c4117
     */
    sendForgotEmailConfirm: async (password, passwordToken) => {
        const res = await JwtAPI.request('post', '1', '/register/password', { password, passwordToken });
        return res;
    },
};

export default API;
