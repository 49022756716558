/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractAdd
 * path: /onboarding/vertrag/import
 *
 * this is a page for selecting how to add contracts - through finApi or manual - in onboarding proccess
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import FinAPICore from '../../core/FinAPI';
import Loading from '../../components/elements/Loading';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import YoutubeVideo from '../../components/elements/YoutubeVideo';
import Button from '../../components/buttons/Button';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import config from '../../config';
import trans from '../../trans';
import ImgFirm1 from '../../assets/firm-1.png';
import ImgFirm2 from '../../assets/firm-2.png';
import ImgFirm3 from '../../assets/firm-3.png';
import ImgFirm4 from '../../assets/firm-4.png';

const ContractAddPage = observer(() => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //ref variable for popup
    const refAlert = useRef();

    //generate webform and go to this page
    const finAPIStart = async () => {
        setIsLoading(true);

        const user = FinAPICore.generateFinApiCustomer();
        const res = await FinAPICore.getWebURL(user);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(trans.t('onboardingContractAddPage', 'finapiError'));
            return false;
        }
        navigate(pageUrl.onboardingFinApiImport, { state: { formId: res.data.id } });

        return true;

        // ContractsStore.setFinAPIContracts(data);
        // navigate(pageUrl.onboardingContractList, { state: { isFinapi: true } });
    };

    return (
        <div className="app onboarding-contract-add-page">
            <Header expertMsg={trans.t('onboardingContractAddPage', 'expertMsg')} />
            <div className="content">
                <div className="wrapper-big">
                    <h1>{trans.t('onboardingContractAddPage', 'h1')}</h1>
                    <div className="buttons">
                        <Button
                            role="btnFinApi"
                            text={trans.t('onboardingContractAddPage', 'btnFinApi')}
                            info={trans.t('onboardingContractAddPage', 'finapiExplanation')}
                            onClick={finAPIStart}
                            isRightIcon
                            isWhite
                            isLeftAlign
                            isFullWidth
                            height={79}
                        />
                        <Button
                            role="btnManual"
                            text={trans.t('onboardingContractAddPage', 'btnManual')}
                            onClick={() => navigate(pageUrl.onboardingContractList)}
                            isRightIcon
                            isWhite
                            isLeftAlign
                            isFullWidth
                            height={79}
                            className="margin-top-16"
                        />
                    </div>
                    <h2>{trans.t('onboardingContractAddPage', 'youtubeTitle')}</h2>
                    <YoutubeVideo videoId={config.youtube.onboardingFinapi} width={1024} height={540} autoplay={0} />
                    <div className="firms">
                        <img src={ImgFirm1} alt="" />
                        <img src={ImgFirm2} alt="" />
                        <img src={ImgFirm3} alt="" />
                    </div>
                    <div className="firms">
                        <img src={ImgFirm4} alt="" />
                    </div>
                </div>
            </div>
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractAddPage;
