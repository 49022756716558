/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a bottom
 */

export default function Bottom({ children }) {
    return (
        <div className="bottom" role="bottom">
            <div className="wrapper-big">{children}</div>
        </div>
    );
}
