/**
 * Created by Max Gornostayev on 04/28/22
 *
 * This is a router for routing logic in security pages
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from '../components/elements/Loading';
import pageUrl from '../const/pages';

const RouteAuth = observer(({ UserStore, ContractsStore }) => {
    //check if is authorized
    if (!UserStore.isAuthorized) {
        return <Navigate to={pageUrl.authSigninPage} />;
    }

    //state variables
    const [isLoading, setIsLoading] = useState(!ContractsStore.isLoadedContracts);

    //start countdown when page is mounted
    useEffect(() => {
        if (!ContractsStore.isLoadedContracts) {
            ContractsStore.loadStore();
        }
    }, []);

    reaction(
        () => ContractsStore.isLoadedContracts,
        (v1, v2, reactionContracts) => {
            if (ContractsStore.isLoadedContracts && reactionContracts) {
                reactionContracts.dispose();
                setIsLoading(false);
            }
        }
    );

    if (isLoading) {
        return <Loading />;
    }

    return <Outlet />;
});

export default RouteAuth;
