/**
 * Created by Max Gornostayev on 2/28/21
 *
 * MODEL - parent class for lists models
 */

class Main {
    //model of the object
    model = null;

    /*
     * constructor
     * @var obj - object that contains values of the model
     * @var model - child model that should be used
     */
    constructor(obj, model) {
        this.model = model;

        const defaultOptions = { ...model };
        if (obj) {
            Object.keys(defaultOptions).forEach((key) => {
                defaultOptions[key] = obj.hasOwnProperty(key) ? obj[key] : this[key];
            });
        }
        Object.assign(this, defaultOptions);
    }

    /*
     * get server object from model
     */
    get serverObject() {
        const options = { ...this.model };
        Object.keys(options).forEach((key) => {
            options[key] = this[key] || options[key];
        });
        return options;
    }

    /*
     * set data from object into model fields
     */
    setData(obj) {
        const options = { ...this.model };
        Object.keys(options).forEach((key) => {
            this[key] = obj.hasOwnProperty(key) ? obj[key] : this[key];
        });
        return options;
    }

    /*
     * update data from object
     */
    updateData(obj) {
        const options = { ...this };

        Object.keys(options).forEach((key) => {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && typeof options[key] === 'object') {
                    this[key] = { ...options[key], ...obj[key] };
                } else {
                    this[key] = obj[key];
                }
            }
        });
        return options;
    }

    static transformSyncObj(syncObj, config) {
        const obj = {};
        if (syncObj) {
            if (syncObj.fields && config.selectFields) {
                Object.keys(config.selectFields).map((key) => (obj[key] = syncObj.fields.hasOwnProperty(config.selectFields[key]) ? syncObj.fields[config.selectFields[key]] : ''));
            }

            obj.id = config.id === 'techId' && syncObj.techId ? syncObj.techId : syncObj.id;

            if (config.title === 'name' && syncObj.name) {
                obj.title = syncObj.name;
            }
            if (config.logo && syncObj.hasOwnProperty(config.logo) && syncObj[config.logo]) {
                obj[config.logo] = syncObj[config.logo];
            }
            if (config.values && config.values.isBrokerPossible && obj.isBrokerPossible) {
                obj.isBrokerPossible = parseInt(obj.isBrokerPossible, 10) === config.values.isBrokerPossible.yes;
            }
        }
        return obj;
    }
}

export default Main;
