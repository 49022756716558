/**
 * Created by Max Gornostayev on 04/28/22
 *
 * This is a router for routing logic in authorization proccess
 */

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import pageUrl from '../const/pages';

const RouteAuth = ({ UserStore }) => {
    //get location variable
    const location = useLocation();

    if (location.pathname === pageUrl.authSignupStepFour) {
        return <Outlet />;
    }

    if (location.pathname !== pageUrl.authSigninPage) {
        //check if terms is accepted
        // if (UserStore.isNotTermsAccepted) {
        //     return <Navigate to={pageUrl.onboardingTerms} />;
        // }
        //check if user existed
        const { customer } = UserStore;
        if (customer.isTemp) {
            return <Navigate to={pageUrl.onboardingUserData} />;
        }
    } else if (UserStore.isAuthorized) {
        return <Navigate to={pageUrl.secureDashboard} />;
    }

    return <Outlet />;
};

export default RouteAuth;
