/**
 * Created by Vitalii Bilous on 06/04/22
 *
 * this is a popup for referal link
 */

import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import HeaderSecure from '../../../components/theme/HeaderSecure';
import Button from '../../../components/buttons/Button';
import trans from '../../../trans';
import '../../../styles/secure.scss';
import QRCode from 'react-qr-code';
import config from '../../../config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from '../../../components/popups/Alert';

const ReferralPage = observer(({ UserStore }) => {
    //ref variable for popup
    const alertRef = useRef();

    //get current customer
    const { customer } = UserStore;

    //get referral link
    const refLink = config.webappUrl + '/?referalID=' + customer.phoneNumber;

    //send message from alert component about success copied referral link
    const copy = () => {
        alertRef.current.show(trans.t('referralShareQR', 'alertMessage'));
    };

    //converting QR code (svg) into an image (png) for further download
    const onImageDownload = () => {
        const svg = document.getElementById('QRCode');
        // eslint-disable-next-line
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // eslint-disable-next-line
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.download = 'QRCode';
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    //main rendering
    return (
        <div className="app secure-customer-referral-page">
            <HeaderSecure isTopMenu isSlideMenu>
                <div className="wrapper-big">
                    <h2>{trans.t('referralShareQR', 'h2')}</h2>
                    <div className="container-referral-description">{trans.t('referralShareQR', 'textDescroption')}</div>
                </div>
            </HeaderSecure>
            <div className="content">
                <div className="wrapper-big">
                    <div className="container-inner">
                        <div className="container-referral">
                            <div className="container-qr">
                                <div className="share-qr-code">
                                    <div className="share-qr-code-image-text">
                                        <h3>{trans.t('referralShareQR', 'h3ImageQR')}</h3>
                                    </div>
                                    <div className="share-qr-code-image">
                                        <QRCode id="QRCode" size={146} value={refLink} />
                                    </div>
                                    <div className="share-qr-code-btndownload">
                                        <Button text={trans.t('referralShareQR', 'btnDownload')} onClick={onImageDownload} role="btnDownloadQR" />
                                    </div>
                                </div>
                                <div className="share-qr-copy">
                                    <div className="share-qr-copy-clipboard-text">
                                        <h3>{trans.t('referralShareQR', 'h3ClipBoard')}</h3>
                                    </div>
                                    <div className="share-qr-copy-input">
                                        <div className="share-qr-copy-text">{refLink}</div>
                                    </div>
                                    <div className="share-qr-copy-button">
                                        <CopyToClipboard text={refLink} onCopy={copy}>
                                            <button className="button">{trans.t('referralShareQR', 'btnCopyLink')}</button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="share-qr-note">{trans.t('referralShareQR', 'bottomNote')}</div>
                </div>
            </div>
            <Alert ref={alertRef} />
        </div>
    );
});

export default ReferralPage;
