/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a button link component
 */

export default function ButtonLink({ onClick, text, isInline, isSmallFont, className, isTouch, isBlue, isRed, role }) {
    //on lick handler when click on the link
    const click = () => {
        if (onClick) {
            onClick();
        }
    };
    //prepare class name for container
    let clssName = 'button-link';
    if (isInline) {
        clssName += ' display-inline';
    }
    if (isSmallFont) {
        clssName += ' small';
    }
    if (className) {
        clssName += ' ' + className;
    }
    if (isTouch) {
        clssName += ' touch-height';
    }
    if (isBlue) {
        clssName += ' blue';
    }
    if (isRed) {
        clssName += ' red';
    }
    if (!role) {
        role = 'button-link';
    }
    return (
        <div className={clssName} onClick={click} role={role}>
            <span>{text}</span>
        </div>
    );
}
