/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is main import file
 */

import AuthAPI from './AuthAPI';
import JwtAPI from './JwtAPI';
import auth from './v1/auth';
import finAPI from './v1/finAPI';
import sync from './v1/sync';
import customer from './v1/customer';
import contract from './v1/contract';

class API {
    static auth = auth;
    static finAPI = finAPI;
    static sync = sync;
    static customer = customer;
    static contract = contract;

    /*
     * set debug mode
     */
    static setDebug(isDebug) {
        AuthAPI.setDebug(isDebug);
        JwtAPI.setDebug(isDebug);
    }
}
export default API;
