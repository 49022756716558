/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows file dropzone in form
 */

import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import trans from '../../trans';

export default function FileDropzone({ role, documents, onChange }) {
    // stte variables
    const [files, setFiles] = useState(documents || []);

    // dropzones variables
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        onDrop: (arr) => {
            const newFiles = [...files, ...arr];
            onChangeHandler(newFiles);
        },
    });

    // remove handler
    const removeFile = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        onChangeHandler(newFiles);
    };

    //change files array handler
    const onChangeHandler = (arr) => {
        if (onChange) {
            onChange(arr);
        }
        setFiles(arr);
    };

    // UI - list of documents
    const filesUI = files.map((file, i) => (
        <div key={i} className="list-item">
            <div className="name">{file.name}</div>
            <div className="btnRemove" onClick={() => removeFile(i)} />
        </div>
    ));

    // Make sure to revoke the data uris to avoid memory leaks
    useEffect(() => {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <div className="form-files" role={role}>
            <div className="title">{trans.t('common', 'dropzoneTitle')}</div>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="text">{trans.t('common', 'dropzoneText')}</div>
                <div className="btn">{trans.t('common', 'dropzoneBtn')}</div>
            </div>
            <div className="list">{filesUI}</div>
        </div>
    );
}
