const info = [
    //infoAdvisement
    { type: 'secTitle', text: 'Gesetzliche Erstinformationen'},
    { type: 'textTitl', text: "OWL Versicherungskontor Gmb\nRathausplatz 7\n33098 Paderborn\nVertretungsberechtigte Geschäftsführer: Wolf-Dieter Jordan, Andre Weller"},
    { type: 'textTitl', text: "Registergericht Handelsregister Amtsgericht Paderborn\nRegisternummer\nHRB 9796\nSteuernummer"},
    { type: 'textTitl', text: "E-Mail   info@owlvv.de\nTelefon  +49 (0)5251 – 294 178\nTelefax  +49 (0)5251 – 294 226"},
    { type: 'textTitl', text: "Sie beauftragen die OWL Versicherungskontor GmbH (im Folgenden OWLVK) mit der Vermittlung von Versicherungsverträgen, einschließlich der Vorbereitungen sowie nach Abschluss dieser Verträge mit der Betreuung und Verwaltung, insbesondere der Mitwirkung bei der Schadenregulierung. Bei Abschluss dieser Vereinbarung werden bereits bestehende Versicherungs- und Finanzverträge nur mit einbezogen, falls diese der OWL VK GmbH bekannt sind und wenn dies ausdrücklich vereinbart wird."},
    { type: 'textTitl', text: "Die OWLVK ist als Versicherungsmakler nach § 34d Abs. 1 der Gewerbeordnung tätig und im Vermittlerregister unter der Nummer D-QY8S-SPZ6H-24 registriert."},
    { type: 'textTitl', text: "Gemeinsame Registerstelle:"},
    { type: 'textTitl', text: "Deutscher Industrie- und Handelskammertag (DIHK) e.V.\nBreite Str. 29\n10178 Berlin\nTelefon: 030-20308-0\nRegisterabruf: www.vermittlerregister.info"},
    { type: 'textTitl', text: "Teilnahme am Streitbeilegungsverfahren gemäß § 36 Verbraucherstreitbeilegungsgesetz"},
    { type: 'textTitl', text: "Wir sind bereit, an einem Streitbeilegungsverfahren vor folgenden Verbraucherschlichtungsstellen teilzunehmen:"},
    { type: 'textTitl', text: "1.	Versicherungsombudsmann e.V. - Postfach 08 06 32 - 10006 Berlin - www.versicherungsombudsmann.de\n2.	Ombudsmann private Kranken- und Pflegeversicherung - Postfach 06 02 22 - 10052 Berlin - www.pkv-ombudsmann.de\n3.	Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V. - Straßburger Str. 8 - 77694 Kehl - www.verbraucher-schlichter.de“"},
    { type: 'textTitl', text: "Die OWLVK besitzt keine direkte oder indirekte Beteiligung von über zehn Prozent an den Stimmrechten oder am Kapital eines Versicherungsunternehmens. Kein Versicherungsunternehmen oder Mutterunternehmen eines Versicherungsunternehmens besitzt eine direkte oder indirekte Beteiligung von über zehn Prozent an den Stimmrechten bzw. am Kapital der OWL VK."},
    { type: 'textTitl', text: "Emittenten und Anbieter, zu deren Finanzanlagen Vermittlungs- oder Beratungsleistungen angeboten werden"},
    { type: 'textTitl', text: "Wir vermitteln und beraten aktuell zu folgenden Emittenten und Anbietern:"},
    { type: 'subTitle', text: 'A'},
    { type: 'textSubT', text: "ADCURI GmbH"},
    { type: 'textSubT', text: "Advigon Versicherung AG"},
    { type: 'textSubT', text: "Allianz Lebensversicherungs AG"},
    { type: 'textSubT', text: "Allianz Private Krankenversicherungs AG"},
    { type: 'textSubT', text: "Allianz Versicherung AG"},
    { type: 'textSubT', text: "Alte Leipziger Bauspar AG"},
    { type: 'textSubT', text: "ALTE LEIPZIGER Lebensversicherung a.G."},
    { type: 'textSubT', text: "Alte Leipziger Versicherung AG"},
    { type: 'textSubT', text: "Alte Oldenburger Krankenversicherung Versicherungsverein a.G."},
    { type: 'textSubT', text: "Ammerländer Versicherung"},
    { type: 'textSubT', text: "ARAG Allgemeine Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "ARAG Krankenversicherungs-AG"},
    { type: 'textSubT', text: "ARAG Lebensversicherungs-AG"},
    { type: 'textSubT', text: "AUXILIA Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Auxilia Versicherungs AG"},
    { type: 'textSubT', text: "AXA Krankenversicherung AG"},
    { type: 'textSubT', text: "AXA Lebensversicherung AG"},
    { type: 'textTitl', text: "AXA Versicherung AG"},
    { type: 'subTitle', text: 'B'},
    { type: 'textSubT', text: "Baden-Badener Versicherung AG"},
    { type: 'textSubT', text: "Barmenia Krankenversicherung a.G."},
    { type: 'textSubT', text: "Barmenia Lebensversicherung a.G."},
    { type: 'textSubT', text: "Basler Lebensversicherung AG"},
    { type: 'textSubT', text: "Bavaria Direkt"},
    { type: 'textSubT', text: "Bayerische Beamtenkrankenkasse AG"},
    { type: 'textSubT', text: "Bayerische Beamten Lebensversicherung a.G."},
    { type: 'textSubT', text: "Bayern-Versicherung Lebensversicherung AG"},
    { type: 'textTitl', text: "BGV-Versicherung AG / Badische Versicherungen"},
    { type: 'subTitle', text: 'C'},
    { type: 'textSubT', text: "Canada Life Assurance Europe Ltd."},
    { type: 'textSubT', text: "cash.life AG"},
    { type: 'textSubT', text: "Central Krankenversicherung AG"},
    { type: 'textSubT', text: "Conceptif Pro GmbH"},
    { type: 'textSubT', text: "Concordia Krankenversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Lebensversicherungs-AG"},
    { type: 'textSubT', text: "Concordia Rechtsschutz-Versicherungs-AG"},
    { type: 'textSubT', text: "Concordia Versicherungs-Gesellschaft a.G."},
    { type: 'textSubT', text: "Condor Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "Condor Lebensversicherungs AG"},
    { type: 'textSubT', text: "Continentale Krankenversicherung a.G."},
    { type: 'textSubT', text: "Continentale Lebensversicherung AG"},
    { type: 'textTitl', text: "Continentale Sachversicherung AG"},
    { type: 'subTitle', text: 'D'},
    { type: 'textSubT', text: "DBV Krankenversicherung AG"},
    { type: 'textSubT', text: "DBV Versicherung AG"},
    { type: 'textSubT', text: "DBV Winterthur Lebensversicherung AG"},
    { type: 'textSubT', text: "DeltaDirekt Lebensversicherung AG"},
    { type: 'textSubT', text: "Deutsche Krankenversicherung AG"},
    { type: 'textSubT', text: "DEVK Allgemeine Versicherungs-AG"},
    { type: 'textSubT', text: "DFV Krankenversicherung AG"},
    { type: 'textSubT', text: "die Bayerische"},
    { type: 'textSubT', text: "Dialog Lebensversicherung AG"},
    { type: 'textSubT', text: "DLVAG"},
    { type: 'textTitl', text: "Domcura AG"},
    { type: 'subTitle', text: 'E'},
    { type: 'textTitl', text: "ERGO Lebensversicherung AG"},
    { type: 'subTitle', text: 'F'},
    { type: 'subTitle', text: 'G'},
    { type: 'textSubT', text: "Generali Versicherung AG"},
    { type: 'textSubT', text: "Gothaer Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "Gothaer Krankenversicherung AG"},
    { type: 'textSubT', text: "Gothaer Lebensversicherung AG"},
    { type: 'textTitl', text: "Grundeigentümer-Versicherung VVaG"},
    { type: 'subTitle', text: 'H'},
    { type: 'textSubT', text: "Die Haftpflichtkasse VVaG"},
    { type: 'textSubT', text: "Hallesche Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Krankenversicherung a.G."},
    { type: 'textSubT', text: "HanseMerkur Lebensversicherung AG"},
    { type: 'textSubT', text: "HanseMerkur Reiseversicherung AG"},
    { type: 'textSubT', text: "HDI Lebensversicherung AG"},
    { type: 'textSubT', text: "HDI-Gerling Firmen und Privat Versicherung AG"},
    { type: 'textSubT', text: "HDI Versicherung AG"},
    { type: 'textTitl', text: "Helvetia schweizerische Lebensversicherungs-AG"},
    { type: 'subTitle', text: 'I'},
    { type: 'textSubT', text: "Ideal Lebensversicherung a.G."},
    { type: 'textSubT', text: "IDEAL Versicherung AG"},
    { type: 'textSubT', text: "Iduna Vereinigte Lebensversicherung a.G."},
    { type: 'textSubT', text: "INTER Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "INTER Krankenversicherung aG"},
    { type: 'textSubT', text: "INTER Lebensversicherung AG"},
    { type: 'textSubT', text: "Interlloyd Versicherungs-AG"},
    { type: 'textTitl', text: "InterRisk Versicherung AG"},
    { type: 'subTitle', text: 'J'},
    { type: 'textSubT', text: "Janitos Krankenversicherung AG"},
    { type: 'textTitl', text: "Janitos Versicherung AG"},
    { type: 'subTitle', text: 'K'},
    { type: 'textSubT', text: "Konzept & Marketing GmbH"},
    { type: 'textTitl', text: "KRAVAG Versicherung AG"},
    { type: 'subTitle', text: 'L'},
    { type: 'textSubT', text: "Landeskrankenhilfe Krankenversicherung a.G."},
    { type: 'textTitl', text: "Lebensversicherung von 1871 a.G. München"},
    { type: 'subTitle', text: 'M'},
    { type: 'textSubT', text: "Mannheimer Krankenversicherung AG"},
    { type: 'textTitl', text: "Münchener Verein Krankenversicherung"},
    { type: 'subTitle', text: 'N'},
    { type: 'textSubT', text: "Nürnberger Beamten Lebensversicherung AG"},
    { type: 'textSubT', text: "Nürnberger Lebensversicherung AG"},
    { type: 'textTitl', text: "Nürnberger Versicherung AG"},
    { type: 'subTitle', text: 'O'},
    { type: 'textTitl', text: "Ostangler Brandgilde VVaG"},
    { type: 'subTitle', text: 'P'},
    { type: 'textTitl', text: "Prisma Life AG"},
    { type: 'subTitle', text: 'R'},
    { type: 'textSubT', text: "R+V Krankenversicherung AG"},
    { type: 'textSubT', text: "R+V Versicherung AG"},
    { type: 'textSubT', text: "Rhion Versicherung AG"},
    { type: 'textTitl', text: "ROLAND Rechtsschutz-Versicherungs-AG"},
    { type: 'subTitle', text: 'S'},
    { type: 'textSubT', text: "SDK Krankenversicherung a.G."},
    { type: 'textSubT', text: "SIGNAL IDUNA Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "SIGNAL IDUNA Pensionskasse AG"},
    { type: 'textSubT', text: "SIGNAL Krankenversicherung a.G."},
    { type: 'textSubT', text: "Skandia Lebensversicherung AG"},
    { type: 'textSubT', text: "SparkassenDirekt Versicherung AG"},
    { type: 'textSubT', text: "Standard Life Versicherung - Zweigniederlassung Deutschland der Standard Life Assurance Limited"},
    { type: 'textSubT', text: "Stuttgarter Lebensversicherung a.G."},
    { type: 'textSubT', text: "Stuttgarter Versicherung AG"},
    { type: 'textSubT', text: "Swiss Life AG"},
    { type: 'textTitl', text: "Syncro 24-Assekuradeur GmbH"},
    { type: 'subTitle', text: 'U'},
    { type: 'textSubT', text: "Union Krankenversicherung AG"},
    { type: 'textSubT', text: "uniVersa Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "uniVersa Krankenversicherung a.G."},
    { type: 'textTitl', text: "uniVersa Lebensversicherung a.G."},
    { type: 'subTitle', text: 'V'},
    { type: 'textSubT', text: "VHV Allgemeine Versicherung AG"},
    { type: 'textSubT', text: "Volkswohl-Bund Lebensversicherung a.G."},
    { type: 'textSubT', text: "VPV Allgemeine Versicherungs-AG"},
    { type: 'textTitl', text: "VPV Lebensversicherung AG"},
    { type: 'subTitle', text: 'W'},
    { type: 'textSubT', text: "Waldenburger Versicherung AG"},
    { type: 'textSubT', text: "Württembergische "},
    { type: 'textSubT', text: "Württembergische Lebensversicherung AG"},
    { type: 'textSubT', text: "Würzburger Versicherungs-AG"},
    { type: 'textSubT', text: "WWK Allgemeine Versicherung AG"},
    { type: 'textTitl', text: "WWK Lebensversicherung a.G."},
    { type: 'subTitle', text: 'Z'},
    { type: 'textSubT', text: "Zurich Deutscher Herold Lebensversicherung AG"},
    { type: 'textSubT', text: "Zurich Gruppe Deutschland"},
    { type: 'textSubT', text: "Zurich Versicherung AG"}
];

export default info;
