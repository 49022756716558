/**
 * Created by Max Gornostayev on 09/26/22
 *
 * pageId: authForgotEmailConfirm
 * path: /auth/forgot/email/confirm
 *
 * this is a forgot page where user can reset his password using email
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Loading from '../../components/elements/Loading';
import Validation from '../../lib/Validation';
import Customer from '../../models/entity/Customer';
import InputText from '../../components/forms/InputText';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';

const ForgotEmailConfirmPage = observer(() => {
    //get search query object
    const [searchParams] = useSearchParams();
    const emailToken = searchParams.get('token');

    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(emailToken || '');

    //ref variable
    const refCode = useRef();
    const refPassword = useRef();
    const refRePassword = useRef();
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const ctaClick = async () => {
        let formValidated = true;

        let validObj = refPassword.current.validate();
        if (!validObj.isValid) {
            refPassword.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        validObj = refRePassword.current.validate();
        if (!validObj.isValid) {
            refRePassword.current.setValidation(validObj.isValid, validObj.validateMsg);
            formValidated = false;
        }

        if (!formValidated) {
            return false;
        }

        setIsLoading(true);

        const res = await Customer.sendForgotEmailConfirm(password, code);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        navigate(pageUrl.authSigninPage);

        return true;
    };

    //main rendering
    return (
        <div className="app auth-forgot-confirm-page">
            <Header>
                <h1>{trans.t('authForgotEmailConfirmPage', 'h1')}</h1>
            </Header>
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        <InputText
                            ref={refCode}
                            role="codeTestId"
                            title={trans.t('authForgotEmailConfirmPage', 'code')}
                            defaultValue={emailToken || ''}
                            onChange={(val) => setCode(val)}
                            className="margin-top-16"
                            validateFunc={(val) => Validation.emptyValue(val)}
                        />
                        <InputText
                            ref={refPassword}
                            role="passwordTestId"
                            title={trans.t('authForgotEmailConfirmPage', 'password')}
                            type="password"
                            className="margin-top-16"
                            onChange={(val) => setPassword(val)}
                            validateFunc={(val) => Validation.password(val)}
                        />
                        <InputText
                            ref={refRePassword}
                            role="rePasswordTestId"
                            title={trans.t('authForgotEmailConfirmPage', 'rePassword')}
                            type="password"
                            className="margin-top-16"
                            validateFunc={(val) => Validation.passwordConfirm(val, password)}
                        />
                        <Button role="btnCta" text={trans.t('authForgotEmailConfirmPage', 'btnNext')} onClick={ctaClick} isFullWidth className="margin-top-48" />
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ForgotEmailConfirmPage;
