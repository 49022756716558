/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a bottom links component
 */

import React, { useRef } from 'react';
import PopupTerms from '../popups/PopupTerms';
import ButtonLink from '../buttons/ButtonLink';
import config from '../../config';
import trans from '../../trans';

export default function BottomLinks({ isBgBlue }) {
    //ref variable for popup
    const popupTermsRef = useRef();

    let className = 'bottom-links';
    if (isBgBlue) {
        className += ' bottom-links-blue';
    }
    return (
        <div className={className}>
            <div className="wrapper">
                <ButtonLink text={trans.t('common', 'bottomImpressum')} onClick={() => window.open(config.urls.impressumLink, '_blank').focus()} isInline isSmallFont />
                <ButtonLink text={trans.t('common', 'bottomInfo')} isInline isSmallFont onClick={() => popupTermsRef.current.show('info')} />
                <ButtonLink text={trans.t('common', 'bottomPrivacy')} isInline isSmallFont onClick={() => popupTermsRef.current.show('privacy')} />
            </div>
            <PopupTerms ref={popupTermsRef} />
        </div>
    );
}
