/**
 * Created by Max Gornostayev on 09/26/22
 *
 * pageId: authSegister
 * path: /start/register
 *
 * this is a start page where user could select what he needs: register with person or company account
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import ButtonLink from '../../components/buttons/ButtonLink';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/auth.scss';

const StartRegisterPage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const nextPrivatePerson = () => {
        ContractsStore.clearStore();
        UserStore.clearStore();
        UserStore.setIsCompany(false);
        UserStore.setUserDataFromStore();
        navigate(pageUrl.onboardingTerms);
    };

    //button handler
    const nextCompany = () => {
        ContractsStore.clearStore();
        UserStore.clearStore();
        UserStore.setIsCompany(true);
        UserStore.setUserDataFromStore();
        navigate(pageUrl.onboardingTerms);
    };

    //main rendering
    return (
        <div className="app auth-start-page" role="app">
            <Header>
                <h1>{trans.t('authStartRegisterPage', 'h1')}</h1>
            </Header>
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        <div className="text">{trans.t('authStartRegisterPage', 'text')}</div>
                        <Button role="btnPrivatePerson" text={trans.t('authStartRegisterPage', 'registerPrivateBtn')} onClick={nextPrivatePerson} isFullWidth className="margin-top-48" />
                        <Button role="btnCompany" text={trans.t('authStartRegisterPage', 'registerCompanyBtn')} onClick={nextCompany} isFullWidth className="margin-top-12" isTransparent />
                        <div className="row-center">
                            <ButtonLink
                                role="btnLogin"
                                text={trans.t('authStartRegisterPage', 'registerLoginBtn')}
                                onClick={() => navigate(pageUrl.authStartLogin)}
                                className="margin-top-70"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
        </div>
    );
});

export default StartRegisterPage;
